import * as React from 'react';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';

import {observer} from 'mobx-react-lite';

import * as colors from './Colors';
import MessageAttach from '../components/MessageAttach';
import { MessageAttachment } from '../components/Attach';
import MsgType from '../../../../types/MsgType';
import { Button, IconButton, SvgIcon } from '@mui/joy';
import { Forward, Replay, Reply } from '@mui/icons-material';
import msgStore from '../../../../store/MsgStore';


type MsgBubbleProps = {
  me: boolean;
  username:string,
  message: MsgType;
  msgDt: string;
  anchor: number;
  setreplyMessage: (value: MsgType) => void;
};

export const MsgBubble = observer((props: MsgBubbleProps) => {
  const {me, username,message, msgDt, anchor} = props;
   
  
  function componentKey(...args: string[]) {
    let key = ""
    args.forEach((value) => {key = key + value;});
    return key
  }

  function RepMsgSet(){
      props.setreplyMessage(message);
  }


  return (
    <Box sx={{maxWidth: '75%', minWidth: 'auto'}}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{mb: 0.25}}
      >
        <Typography level="body-sm">
          {username}
        </Typography>
        <Typography level="body-xs">
          {msgDt}
          {(anchor === 2) && (me) && (<Typography sx={{fontStyle: 'italic', fontWeight: 'light', typography: 'body2'}}>(в очереди)</Typography>)}
          {(anchor === 3) && (me) && (<Typography sx={{fontStyle: 'italic', fontWeight: 'light', typography: 'body2'}}>(ошибка)</Typography>)}
        </Typography>
        <IconButton
            onClick={RepMsgSet}
            sx={{
              "--IconButton-size": "21px"
            }}
          >
            <Reply />
          </IconButton>
      </Stack>
      <Box
        sx={{position: 'relative'}}
      >
        <Sheet
          color={me ? 'primary' : 'neutral'}
          variant={me ? 'solid' : 'soft'}
          sx={{
            p: 1.25,
            borderRadius: 'lg',
            borderTopRightRadius: 'lg',
            borderTopLeftRadius: 0,
            backgroundColor: me
              ? colors.backBubleMe
              : colors.backBubleThem,
          }}
        >
          {(message?.replymessageid!=='0'&&message?.replymessageid&&message?.replymessageid!==undefined)&& (
             <Box
             sx={{ position: 'relative' }}
           >
             <Sheet
               color={'primary'}
               variant={'solid'}
               sx={{
                 p: 1.25,
                 borderRadius: 'lg',
                 borderTopRightRadius: 'lg',
                 borderTopLeftRadius: 0,
                 backgroundColor: "C7DFF7"
               }}
             >
             <Typography
               level="body-sm"
               sx={{
                 color: "#FFFFFF",
                 // overflowWrap: "break-word",
                 overflowWrap: "anywhere"
   
               }}
             >
               {msgStore.getMsgByID(message.replymessageid)?.content}
             </Typography>
             </Sheet>
             </Box>
          )}
          <Typography
            level="body-sm"
            sx={{
              color: me
                ? colors.fontBubleMe
                : colors.fontBubleThem,
              // overflowWrap: "break-word",
              overflowWrap: "anywhere"

            }}
          >
            {message.content}
          </Typography>
          
          {message?.attachments && message?.attachments?.map((attachment: MessageAttachment) => {
            return (
              <>
                <Typography fontSize="sm" level="title-sm" sx={{
                  color: me
                    ? colors.fontBubleMe
                    : colors.fontBubleThem
                }}>Вложения</Typography>

                <MessageAttach key={componentKey("1", "bub")} attachment={attachment} me={me}/>
              </>
            )
          })}
        </Sheet>

      </Box>

    </Box>
  );
}
)

