import {observer} from "mobx-react-lite";
import MsgsHead from "./msgs/MsgsHead";
import {Box, Sheet, Stack, Typography} from "@mui/joy";
import MsgsBody from "./msgs/MsgsBody";
import MsgsBottom from "./msgs/MsgsBottom";
import MsgType from "../../../types/MsgType";
import React from "react";
import msgStore from "../../../store/MsgStore";

const ContentPanel = observer(() => {
    const [replyMessage, setreplyMessage] = React.useState<MsgType|undefined>(undefined);
    

    return (
        <Sheet
            sx={{
                //height: { xs: 'calc(100dvh - var(--Header-height))', lg: '100dvh' },//***fuckbar
                height: "calc(100dvh - var(--Header-height))",
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'background.level2',
            }}
        >
            <MsgsHead />
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    minHeight: 0,
                    px: 2,
                    py: 3,
                    overflowY: 'scroll',
                    flexDirection: 'column-reverse',
                }}
            >
                <Stack spacing={2} justifyContent="flex-end">
                    <MsgsBody replyMessage={replyMessage} setreplyMessage={setreplyMessage}/>
                </Stack>
            </Box>
            <MsgsBottom replyMessage={replyMessage} setreplyMessage={setreplyMessage}/>
        </Sheet>
        
    );

});
export {ContentPanel};
