import {CircularProgress, IconButton, Sheet, Typography} from "@mui/joy";
import RefreshIcon from '@mui/icons-material/Refresh';
import * as React from "react";


function ListDecorator({children}: {
  children: React.ReactElement
}) {

  return (
    <>
      <Sheet
        sx={{
          display: 'flex',
          flex: 1,
          overflow: 'auto',
          borderRadius: 'md',
          borderStyle: "solid",
          borderColor: "neutral.softHoverBg",
          borderWidth: 1,

        }}
      >
        {children}
      </Sheet>
    </>
  );
}

export {ListDecorator};
