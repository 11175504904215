import {Box, Chip, ListItem, ListItemButton, ListItemContent, ListItemDecorator, Stack, Tooltip, Typography} from "@mui/joy";
import AvatarWithStatus from "../../../../components/AvatarWithStatus";
import {DialogTypeC} from "../../../../types/DialogType";
import dialogsStore from "../../../../store/DialogsStore"
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import DraftsTwoToneIcon from '@mui/icons-material/DraftsTwoTone';
import MailIcon from '@mui/icons-material/Mail';
import {observer} from "mobx-react-lite";
import {identity} from "../../../../types/Alias";
import {DefaultColorPalette} from "@mui/joy/styles/types";
import {useTranslation} from "react-i18next";
import {dateReFormat, dateToMs_String} from "../../../../utils";
import MsgType from "../../../../types/MsgType";
import currentContext from "../../../../store/CurrentContext";
import {ItemSocket, send} from "../../../../net/socket/genericSocket";
import MsgsBottom from "../msgs/MsgsBottom";
import {dialogRest} from "../../../../net/rest/DialogRest";
import currentInfoStore from "../../../../store/CurrentInfoStore";
import msgStore from "../../../../store/MsgStore";

import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AndroidIcon from '@mui/icons-material/Android';
import PeopleIcon from '@mui/icons-material/People';

function getColorByPriority(p: identity): string {

    switch (p) {
        case "1": {return "danger.400";}
        case "2": {return "warning.300";}
        case "3": {return "primary.300";}
        case "4": {return "neutral.300"}
        default: {return "neutral.300"}
    }
}




type MyDialogItemType =
    {
        dialog: DialogTypeC
    }
const MyDialogItem = observer((props: MyDialogItemType) => {
    const {t} = useTranslation();
    const {dialog} = props;


    

    function selectDialog() {
        dialogsStore.select(dialog.dialogid);
        dialogsStore.changeNewMSG(dialog.dialogid, false);
        msgStore.LoadMsg("10",false);
    }
    return (
        <ListItem
            variant={"plain"}

            sx={{
                backgroundColor: "inherit",
                ml: 0.5,
                mr: 0.5,
            }} >

            <ListItemButton variant="plain"
                onClick={() => selectDialog()}
                sx={{
                    flexDirection: 'column',
                    alignItems: 'initial',
                    gap: 1,
                    borderRadius: "md",
                    pr: 0.5,
                    pb: 1,
                    borderRight: 1,
                    borderLeft: 1,
                    //borderLeftColor: getColorByPriority(dialog.priority.priorityid),
                    //borderRightColor: getColorByPriority(dialog.priority.priorityid),
                    borderLeftColor: "GrayText",
                    borderRightColor: "GrayText",
                    backgroundColor: (dialogsStore.Selected?.dialogid === dialog.dialogid) ? "primary.softHoverBg" : "inherit"
                }}
            >
                <Stack direction="row" spacing={1.5}>


                    {((dialogsStore.NewMessage(dialog.dialogid)) && !(dialogsStore.Selected?.dialogid === dialog.dialogid))
                        ? (<MailIcon sx={{fontSize: 22}} color="primary" />)
                        : (<DraftsOutlinedIcon sx={{fontSize: 22}} />)
                    }
                    <Tooltip title={dialog.user.nameds} variant="solid">
                        <Box sx={{flex: 1}}>
                            <Typography noWrap level=
                                {((false) && !(dialogsStore.Selected?.dialogid === dialog.dialogid)) ? "title-sm" : "body-sm"}
                                sx={{maxWidth: 150}}>
                                {dialog.user.nameds}
                            </Typography>
                        </Box>
                    </Tooltip>
                    {/* <Tooltip title={t('en_priority_'+dialog.priority.priorityid)} variant="solid">
                        <Box
                            sx={{
                                lineHeight: 1.5,
                                textAlign: 'right',

                            }}
                        >
                            <Typography level="body-xs">{dateReFormat(dialog.lastupdate,"yyyy-mm-ddTHH:MM:SS","dd-mm-yyyy HH:MM")}</Typography>

                        </Box>
                    </Tooltip> */}
                    {/* 
                    <Typography noWrap level=
                        {((dialog.news) && !(dialogsStore.Selected?.id_dialog === dialog.id_dialog)) ? "title-md" : "body-md"}
                        sx={{maxWidth: 150}}>
                        {dialog.user.ds_name}
                    </Typography>
                    <Box sx={{flex: 1}}>

                    </Box>
                    <Tooltip title={dialog.priority.ds_priority} variant="plain">
                        <Box
                            sx={{
                                lineHeight: 1.5,
                                textAlign: 'right',

                            }}
                        >
                            {((dialog.news) && !(dialogsStore.Selected?.id_dialog === dialog.id_dialog))
                                ? (<MailIcon sx={{fontSize: 16}} color="primary" />)
                                : (<DraftsTwoToneIcon sx={{fontSize: 16}} />)
                            }
                            <Typography level="body-xs">{dialog.updateDate}</Typography>

                        </Box>
                    </Tooltip> */}

                </Stack>

                {(dialog.assignee) && (
                    <Stack direction="row" sx={{pl: 1}}>
                        <Stack direction="column" sx={{pr: 1, width: 150}} >
                            <Chip color="neutral" variant="outlined" size="sm" startDecorator={<SupportAgentIcon />}
                                sx={{mb: 0.2, p: 0.2}}>
                                {((dialog.assignee.userid) && Number(dialog.assignee.userid) > 0) ? dialog.assignee.nameds : "---"}
                            </Chip>
                            <Chip color="neutral" variant="outlined" size="sm" startDecorator={<PeopleIcon />}>
                                {(dialog.team.teamid) ? dialog.team.teamds : "---"}</Chip>

                        </Stack>
                        <Stack direction="column" >
                            <Box
                                sx={{
                                    lineHeight: 1.5,
                                    textAlign: 'center',

                                }}
                            >
                                <Typography level="body-xs">{dateReFormat(dialog.lastupdate, "yyyy-mm-ddTHH:MM:SS", "dd-mm-yyyy HH:MM")}</Typography>

                            </Box>
                            <Box
                                sx={{
                                    lineHeight: 1.5,
                                    textAlign: 'right',


                                }}

                            >

                                <Typography
                                    sx={{color: getColorByPriority(dialog.priority.priorityid)}}
                                    level="body-xs">{t('en_priority_' + dialog.priority.priorityid)}</Typography>

                            </Box>
                        </Stack>
                    </Stack>
                )}

                {/* {(dialog.tags) && (
                    <Box display="flex" sx={{pl: 3}}>
                        {dialog.tags.map((value: {id_tag: identity, ds_tag: string}) => {
                            return (<>
                                <Chip color="neutral" variant="soft" size="sm">
                                    {value.ds_tag}</Chip>
                            </>)
                        }

                        )}


                    </Box>
                )} */}

            </ListItemButton>
        </ListItem>
    );
});
export default MyDialogItem;