import {observer} from "mobx-react-lite";

import {

    ReactFlowProvider,
  
} from '@xyflow/react';

import '@xyflow/react/dist/style.css';
import Flow from "./Flow";
import {Sheet} from "@mui/joy";


const ContentPanel = observer(() => {


    return (

        <Sheet
            sx={{
                height: 'calc(100dvh - var(--Header-height))',
                display: 'flex',
                flexDirection: 'column',

            }}
        >

            <ReactFlowProvider><Flow /></ReactFlowProvider>
        </Sheet>



    )

});


export {ContentPanel};