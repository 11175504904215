import dialogsStore from "../../store/DialogsStore";
import msgStore from "../../store/MsgStore";
import { DialogTypeC } from "../../types/DialogType";
import MsgType from "../../types/MsgType";

// MType:
// NEWQUEST- от сервера кнопки, 
// NEWMESSAGE - от сервера новое сообщение, 
// GENERALACKNOW от сервера ответы на сообщени, 
// UPDDIALOG- от сервера апдейт диалога, 
// NEWDIALOGACKNOW - от сервера ответ на открытие нового диалога

export default function messageDispatcher(s: string,MType:string) {
    const j = JSON.parse(s);
    if(MType==="GENERALACKNOW"){
      return;//Отбивка по своему сообщунию. 
    }else if (MType==="NEWMESSAGE"){
      let Msg = j as MsgType;
      Msg.isme = false;
      const ChangeMSG = msgStore.GetMSGByTrackID(Msg.trackingid);
      if(ChangeMSG){
        msgStore.addMsg(ChangeMSG);
        return;
      }
      msgStore.addMsg(Msg);
      if (!(Msg.dialogid==dialogsStore.Selected?.dialogid)){
        if(!(Msg.dialogid===undefined)){
          dialogsStore.changeNewMSG(Msg.dialogid,true);
        }
      }
    }else if (MType==="NEWQUEST"){
    
    }else if (MType==="UPDDIALOG"){
      let Dlg = j as DialogTypeC;
      dialogsStore.addDialog(Dlg);
    }else if (MType==="NEWDIALOGACKNOW"){
      let Dlg = j as DialogTypeC;
      dialogsStore.addDialog(Dlg);
    }        
  }