import {Sheet} from "@mui/joy";
import {observer} from 'mobx-react-lite';
import currentPageStore from "../../store/CurrentPageStore";

import * as bots from "./bots/Layout"
import * as chats from "./chats/Layout"
import * as reports from "./reports/Layout"
import * as teams from "./teams/Layout"
import * as libr from "./libr/Layout"
import * as log from "./logging/Layout"



const BodyLayout=observer( ()=>  {
    return (
        <Sheet
          sx={{
            flex: 1,
            width: '100%',
            mx: 'auto',
            pt: 'var(--Header-height)',

          }}
        >
          {(currentPageStore.Value==="CHAT") && (
            <chats.Layout/>
          )}
          {(currentPageStore.Value==="BOT") && (
            <bots.Layout/>
          )}

          {(currentPageStore.Value==="TEAM") && (
            <teams.Layout/>
          )}
            {(currentPageStore.Value==="REPS") && (
            <reports.Layout/>
          )}
            {(currentPageStore.Value==="LIB") && (
            <libr.Layout/>
          )}
             {(currentPageStore.Value==="BUG") && (
            <log.Layout/>
          )}
      

        </Sheet>
      );
    }
);
    export default BodyLayout;