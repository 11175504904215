import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import Textarea from '@mui/joy/Textarea';
import { Modal, ModalClose, Sheet, Stack, Typography} from '@mui/joy';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {up,send, ItemSocket} from "../../../../net/socket/genericSocket";
import {InnerTextarea} from './StyledTextArea';
import MsgType from '../../../../types/MsgType';
import msgStore from '../../../../store/MsgStore';
import { EditDialog, EditDialogDescriptor, initEditDialogDescriptor } from '../../../../components/EditDialog';
import {useTranslation} from "react-i18next";
import { identity, ustring } from '../../../../types/Alias';
import currentContext from '../../../../store/CurrentContext';
import dialogsStore from '../../../../store/DialogsStore';
import { dateToMs_String } from '../../../../utils';
import { attachRest } from '../../../../net/rest/AttachRest';
import currentInfoStore from '../../../../store/CurrentInfoStore';
import AttachMsg from '../../../../types/AttachMsgType';
import * as colors from './Colors';

export default function MsgsBottom(props: {replyMessage:MsgType|undefined, setreplyMessage: (value: MsgType|undefined) => void}) {


  const [textAreaValue, setTextAreaValue] = React.useState<string>("");
  const [attachedFile, setAttachedFile] = React.useState<File>();
  const refBox = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [alert, setAlertt] = React.useState<string>("");
   //Dialogs state
   const [dlgDescriptor, setDlgDescriptor] = React.useState<EditDialogDescriptor>(initEditDialogDescriptor(handleResultDialog));
   const {t} = useTranslation();

  

  function handleResultDialog(anchor: identity | undefined, isapplied: boolean, result?: Map<string, ustring>, linkedresult?: Map<string, Object>) {
    closeDialog();
    // TODO
    if (isapplied) {

      switch (anchor) {
          case "ADD": {
              const file: File = linkedresult?.get("DocName") as File;
              attachRest.postDoc({
                sucess: (data: any, envoy?: any) => {
                      
                },
                error: (data: any, envoy?: any) => {
                  currentInfoStore.setInfo("Teams " + data, "ERROR");
                },                     
              },
              file,"111"
            );
              setAttachedFile(file);
            }
        break;
      }

  }
}



class GUID {
  private str: string;

  constructor(str?: string) {
      this.str = str || GUID.getNewGUIDString();
  }

  toString() {
      return this.str;
  }

  private static getNewGUIDString() {
      let d = new Date().getTime();
      if (window.performance && typeof window.performance.now === "function") {
          d += performance.now(); 
      }
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
          let r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d/16);
          return (c=='x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
  }
}

  function handleClick(){
      const StrGUID: string = new GUID().toString();
      const msgValue: MsgType = {
        trackingid: StrGUID,
        userid: currentContext.UserID,
        dialogid: dialogsStore.Selected?.dialogid,
        messagetypeid: 0,
        content: textAreaValue,
        replymessageid: undefined,
        senttime: dateToMs_String(new Date()),
      };
      let ItemSocet: ItemSocket = {
        msg: msgValue
      }
      if (attachedFile){
        const attach:AttachMsg = {
          filenname: attachedFile.name,
          docid:attachedFile.size.toString()
        }
        msgValue.attachments = [attach];
      }
      if(props.replyMessage){
        msgValue.replymessageid=props.replyMessage.messageid;
      }

      if(textAreaValue!==""){
        msgStore.addMsg(msgValue);
        send(ItemSocet);
      }
          
      setTextAreaValue("");
      setAttachedFile(undefined);
      props.setreplyMessage(undefined);
  }

  //Dialogs show
  function showDialog() {
    setDlgDescriptor((reset: EditDialogDescriptor) => {
        reset.visible = true;
        return {...reset};
    });
}
 //Dialogs close
 function closeDialog() {
  setDlgDescriptor((reset: EditDialogDescriptor) => {
      return {anchor: undefined, visible: false, elements: [], handlResult: reset.handlResult};
  });
}

 //Add document modal window
 function handleClickAttach() {
  dlgDescriptor.title = t("Add_Title");
  dlgDescriptor.text = t("Add_Doc");
  dlgDescriptor.apply = t("Button_ADD");
  dlgDescriptor.cancel = t("Button_Cancel");
  dlgDescriptor.elements = [
      {format: "FILE", key: "DocName", title: t("Doc_Filename")},
  ];
  dlgDescriptor.anchor = "ADD";
  showDialog();
}
 

function formatBytes(size: number, decimals = 2) {
  if (!+size) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(size) / Math.log(k))
  return `${parseFloat((size / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
 
function shrinkText(str: string, size: number) {
  if (str.length > size - 3) {
    return str.substring(0, size - 3) + "...";
  }
  return str
}
  
  return (
    <Box sx={{px: 2, pb: 3}} ref={refBox}>
      <input id="inputLoadFile" type="file" style={{display: 'none'}} 
      //onChange={handleFileChange} 
      />
      <FormControl>
        <Textarea
          //begin Styled
          slots={{textarea: InnerTextarea}}
          slotProps={{textarea: {placeholder: 'Сообщение'}}}
          //end Styled
          //placeholder={usersStore.current.name}
          aria-label="Message"
          onChange={(e) => {
            setTextAreaValue(e.target.value);
          }}
          value={textAreaValue}
          minRows={3}
          maxRows={10}
         
          endDecorator={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              flexGrow={1}
              sx={{
                py: 1,
                pr: 1,
                borderTop: '1px solid',
                borderColor: 'divider',
              }}
            >
              <div>
                {attachedFile && (
                  <>
                    <Typography fontSize="sm" level="body-xs" >
                      
                      {shrinkText(attachedFile.name, 22)} ({formatBytes(attachedFile.size)})
                      </Typography>
                  </>
                )}
              </div>
              <div>
                {props.replyMessage && (
                  <>
                    <Box
                      sx={{ position: 'relative' }}
                    >
                      <Sheet
                        color={'primary'}
                        variant={'solid'}
                        sx={{
                          p: 1.25,
                          borderRadius: 'lg',
                          borderTopRightRadius: 'lg',
                          borderTopLeftRadius: 0,
                          backgroundColor: "#0A2744"
                        }}
                      >
                        <Typography level="body-sm"
                        
                          sx={{
                            color: "#FFFFFF",
                            //color: colors.fontBubleMe,
                            overflowWrap: "anywhere"

                          }}>
                          {props.replyMessage.content}
                        </Typography>
                      </Sheet>
                    </Box>

                  </>
                )}
              </div>
              <div>
                {attachedFile && (
                  <Button
                    size="sm"
                    color="primary"
                    sx={{alignSelf: 'center', borderRadius: 'sm', }}
                    variant="plain"
                    onClick={() => setAttachedFile(undefined)}
                  >
                    <DeleteOutlineIcon sx={{transform: `scale(1.2)`}} />
                  </Button>
                )}
                <Button
                  size="sm"
                  color="primary"
                  sx={{alignSelf: 'center', borderRadius: 'sm', }}
                  variant="plain"
                  onClick={handleClickAttach}
                >
                  <AttachFileIcon sx={{transform: `scale(1.2)`}} />
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  sx={{alignSelf: 'center', borderRadius: 'sm', }}
                  onClick={handleClick}
                >
                  <SendRoundedIcon />
                </Button>
              </div>
            </Stack>
          }
          onKeyDown={(event) => {
          if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
              handleClick();
            }
          }}
          sx={{
            '& textarea:first-of-type': {
              minHeight: 72,
              //backgroundColor: 'neutral.solidColor'
            },
          }}
        />
      </FormControl>


      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => {setOpen(false)}}
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose variant="soft" sx={{m: 1}} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >Предупреждение
          </Typography>
          <Typography id="modal-desc" textColor="text.tertiary">
            {alert}
          </Typography>
        </Sheet>
      </Modal>

      <EditDialog descriptor={dlgDescriptor} />

    </Box>
  );
}
