import * as React from 'react';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton, {MenuButtonProps} from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {observer} from 'mobx-react-lite';
import lo from '../../store/Logging';

const ToggleDebug=observer( (props: MenuButtonProps)=> {


    const [open, setOpen] = React.useState(false);


    const handleOpenChange = React.useCallback(
        (event: React.SyntheticEvent | null, isOpen: boolean) => {
            setOpen(isOpen);
        },
        [],
    );

    React.useEffect(() => {
        // const locale: string | undefined = getLocale();
        // if (locale) {
        //     //setActiveLocale(locale);
        //    //s i18n.changeLanguage(locale);
        // }
    },[]);


    // function activateLocale(locale: string) {
    //     i18n.changeLanguage(locale);
    //     setLocale(locale);
    //     setActiveLocale(locale);

    // }
    return (
        <Dropdown open={open} onOpenChange={handleOpenChange} >
            <MenuButton
                sx={props.sx}
                size="sm"
                variant="outlined"
                color={(lo.RestEnable || lo.WsEnable)?"danger":"neutral"}

            ><AdbIcon /></MenuButton>
            <Menu size="sm"
                variant="outlined"
                color="neutral"
            >
                <MenuItem color={(lo.RestEnable)?"danger":"neutral"} onClick={() => { lo.RestEnable=!lo.RestEnable;}}
                >{lo.RestEnable?"✓":""} RestLog</MenuItem>
                <MenuItem color={(lo.WsEnable)?"danger":"neutral"}  onClick={() => { lo.WsEnable=!lo.WsEnable;}}
                >{lo.WsEnable?"✓":""} WSLog</MenuItem>

            </Menu>
        </Dropdown>


    );
});
export default ToggleDebug;
