import { makeAutoObservable } from "mobx";
import DocsType from "../types/DocsType";
import {identity} from "../types/Alias";


/**
 * класс DocsStore отвечает за хранение и предоставление данных по списку файлов
 * и операций над этим список 
 */
class DocsStore {
    private $docs: DocsType[] = [];
    private $selected_docid:identity = "";
    private $selected_filenameds:string = "";

    public constructor() {
        makeAutoObservable(this);
    }

     /** возвращает список документов */
     public getDocs(): DocsType[] {
        return this.$docs;
    }

     /** возвращает список документов по списку id
     * используется фильтрация массива там где у элемента id в списке ids переданной в сигнатуре
    */
     public getDocsByIDs(ids_list: identity[]): DocsType[] {
        return this.$docs.filter((value: DocsType) => {
            return ids_list.indexOf(value.docid) >= 0;
        });
    }

     /** возвращает список документов по списку filename
     * используется фильтрация массива 
    */
     public getDocsByNames(names_list: string[]): DocsType[] {
        return this.$docs.filter((value: DocsType) => {
            return names_list.indexOf(value.filenameds) >= 0;
        });

    }

     /** возвращает один документ по наименованию filename
     */
     public getDocByName(name: string|any): DocsType|undefined {
        const doc=this.getDocsByNames([name]);
        return doc.length===0?undefined:doc[0];
    }

     /** возвращает один документ по ID
     */
     public getDocByID(name: identity|any): DocsType|undefined {
        const doc=this.getDocsByIDs([name]);
        return doc.length===0?undefined:doc[0];
    }

     /** чистит список 
    */
     public clearDocs() {
        this.$selected_docid = "";
        this.$selected_filenameds = "";
        this.$docs = [];

    }

  
    
    

    /** удаляет несколько 
    */
    public deleteDocs(docs: DocsType[]) {
        docs.forEach((value: DocsType) => {
            let i: number = this.$docs.findIndex((valueF: DocsType) => valueF.docid === value.docid);
            if (i >= 0) {
                this.$docs.slice(i, i);
            }
        });
    }

    /** удаляет один
    */
    public deleteDoc(doc: DocsType) {
        let i: number = this.$docs.findIndex((valueF: DocsType) => valueF.docid === doc.docid);
        if (i >= 0) {
            this.$docs.splice(i, 1);
        }   
    } 

    /** add Doc
    */
    public addDoc(_value: DocsType) {
       //SYNC TYPES !!! - Done 11.09.2024
       const value: DocsType =
       {
        docid:String(_value.docid),
        filenameds:String(_value.filenameds),
        content:_value?.content,
        activefl:Boolean(_value.activefl),
        contenturi:_value?.contenturi
       }
        let i: number = this.$docs.findIndex((valueF: DocsType) => valueF.docid === value.docid);
        if (i >= 0) {
            this.$docs[i] = {...value};
        } else {
            this.$docs.push(value);
        }
    
}


   //ids
   public get SelectedIDDoc()
   { return this.$selected_docid }
   public set SelectedIDDoc(value:identity)
   { this.$selected_docid = value }

   //names
   public get SelectedDSFileName()
   { return this.$selected_filenameds}
   public set SelectedDSFileName(value: string)
   { this.$selected_filenameds = value }


}

const docsStore = new DocsStore();
export default docsStore;

