import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import AvatarWithStatus from "../../../../components/AvatarWithStatus";
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import {observer} from 'mobx-react-lite';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {DialogContent, DialogTitle, FormControl, FormLabel, Input, Modal, Button, ModalDialog, Sheet, Divider, DialogActions, Autocomplete, ModalClose, CircularProgress} from '@mui/joy';
import Box from '@mui/joy/Box';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ReplyIcon from '@mui/icons-material/Reply';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import {useTranslation} from 'react-i18next';
import { sockStateStore } from '../../../../store/SockStateStore';
import currentContext from '../../../../store/CurrentContext';
import {redirectRest} from '../../../../net/rest/RedirectRest';
import dialogsStore from '../../../../store/DialogsStore';
import { EditDialog, EditDialogDescriptor, initEditDialogDescriptor } from '../../../../components/EditDialog';
import { identity, ustring } from '../../../../types/Alias';
import UserType from '../../../../types/UserType';
import { TypesOfUsers } from '../../../../store/Enumerations';
import usersStore from '../../../../store/UsersStore';
import teamStore from '../../../../store/TeamStore';
import TeamType from '../../../../types/TeamType';



const MsgsHead = observer(() => {
  const {t} = useTranslation();
   //Dialogs state
   const [dlgDescriptor, setDlgDescriptor] = React.useState<EditDialogDescriptor>(initEditDialogDescriptor(handlResultDialog));
  
   //Dialogs close
   function closeDialog() {
     setDlgDescriptor((reset: EditDialogDescriptor) => {
         return {anchor: undefined, visible: false, elements: [], handlResult: reset.handlResult};
     });
 }

 class GUID {
  private str: string;

  constructor(str?: string) {
      this.str = str || GUID.getNewGUIDString();
  }

  toString() {
      return this.str;
  }

  private static getNewGUIDString() {
      let d = new Date().getTime();
      if (window.performance && typeof window.performance.now === "function") {
          d += performance.now(); 
      }
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
          let r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d/16);
          return (c=='x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
  }
}

 //Dialogs show
 function showDialog() {
     setDlgDescriptor((reset: EditDialogDescriptor) => {
         reset.visible = true;
         return {...reset};
     });
 }
  //Dialogs process result
  function handlResultDialog(anchor: identity | undefined, isapplied: boolean, result?: Map<string, ustring>) {
    closeDialog();
    // TODO
    if (isapplied) {

      switch (anchor) {
        case 'User':
              redirectRest.redirectToUser(
                {
                  sucess: (data: any) => {
                        console.log("ok");

                  },

                  error:  (data: any) => {
                    console.log("error"+data);

                  },
                },
                [["userid",result?.get("UserID") as string],["senderid",currentContext.UserID]
                ,["dialogid",dialogsStore.Selected?.dialogid as string],
                ["trackingid","111111"]]);
              break;
      case 'Team':
              redirectRest.redirectToTeam(
                {
                  sucess: (data: any) => {
                        console.log("ok");

                  },

                  error:  (data: any) => {
                    console.log("error"+data);

                  },
                },
                [["newteamid",result?.get("TeamID") as string],["senderid",currentContext.UserID]
                ,["dialogid",dialogsStore.Selected?.dialogid as string],
              ["trackingid","1111"]]);
              break;
          default:
            break;
        }
      
    }
  }
   
  //Selection
   function addSelection(Comm:string) {
    let TypeOf:TypesOfUsers = TypesOfUsers.Bots;
    let Us:boolean = true;
    if(Comm==="RedirectBot"){
      TypeOf = TypesOfUsers.Bots;
    }if(Comm==="RedirectAgent"){
      TypeOf = TypesOfUsers.Agents;
    }if(Comm==="RedirectTeam"){
      Us=false;
    }
    dlgDescriptor.title = t("Redirect_Add_Title");
    dlgDescriptor.text = t("Redirect_Add_text");
    dlgDescriptor.apply = t("Button_ADD");
    dlgDescriptor.cancel = t("Button_Cancel");
    if(Us){
    dlgDescriptor.elements = [
      {
        format: "SELECT", key: "UserID", title: t("User"), currentValue: "c4", source: [ 
          ...usersStore.Display((value) => value.usertypeid === TypeOf).map<{code:string,value:string}>((item:UserType) =>  {return {code: item.userid,value:item.nameds}})]}]
        dlgDescriptor.anchor = "User";
    }else{
    dlgDescriptor.elements = [
        {
          format: "SELECT", key: "TeamID", title: t("Team"), currentValue: "c4", source: [ 
            ...teamStore.getTeams().map<{code:string,value:string}>((item:TeamType) =>  {return {code: item.teamid,value:item.teamds}})]}]
          dlgDescriptor.anchor = "Team";
        }
    showDialog();
  }

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
          backgroundColor: 'background.body',
        }}
        py={{xs: 2, md: 2}}
        px={{xs: 1, md: 2}}
      >
        <Stack direction="row" spacing={{xs: 1, md: 2}} alignItems="center">
          <IconButton
            variant="plain"
            color="neutral"
            size="sm"
            sx={{
              display: {xs: 'inline-flex', sm: 'none'},
            }}

          >
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
          <Avatar size="lg"><AvatarWithStatus online={(sockStateStore.state==1)}
                                src={""}
                                role={"AGENT"}/></Avatar>
          <div>
            <Typography
              fontWeight="lg"
              fontSize="lg"
              component="h2"
              noWrap
            >
              {currentContext.User?.nameds}
            </Typography>
            <Typography level="body-sm">ID {currentContext.User?.userid}</Typography>
          </div>

        </Stack>
        <Stack spacing={1} direction="row" alignItems="center">


          <Button color='primary' variant='outlined' size='sm' startDecorator={<PrecisionManufacturingIcon />}
            //sx={{display: {xs: 'unset', sm: 'unset'}}}
            onClick={() => {
              addSelection("RedirectBot");
             }}
          >{t("RedirectBot")}
          </Button>

          <Button color='primary' variant='outlined'  size='sm' startDecorator={<ReplyIcon sx={{transform: "rotate(180deg)"}} />}
            //sx={{display: {xs: 'unset', sm: 'unset'}}}
            onClick={() => {
              addSelection("RedirectAgent");
            }}
          >{t("RedirectAgent")}</Button>
          <Button color='primary' variant='outlined'  size='sm' startDecorator={<ReplyAllIcon sx={{transform: "rotate(180deg)"}}/>}
            //sx={{display: {xs: 'unset', sm: 'unset'}}}
            onClick={() => {
              addSelection("RedirectTeam");
            }}
          >{t("RedirectTeam")}</Button>
        </Stack>
      </Stack>
      <EditDialog descriptor={dlgDescriptor} />



    </Box>
  );
});
export default MsgsHead;
