import {List, ListItem, ListItemButton, ListItemContent, ListItemDecorator, Sheet, ListDivider, ListSubheader, MenuItem, CircularProgress, Typography} from "@mui/joy";
import {observer} from "mobx-react-lite";
import {ListDecorator} from "../../../components/ListDecorator";
import ForumIcon from '@mui/icons-material/Forum';
import CommentBankIcon from '@mui/icons-material/CommentBank';
import ArchiveIcon from '@mui/icons-material/Archive';
import {botUsersRest as usersRest} from "../../../net/rest/BotUsersRest";
import MessageIcon from '@mui/icons-material/Message';
import * as React from 'react';
import {useTranslation} from "react-i18next";
import MyMenuItem, {MyMenuItemType} from "./components/MyMenuItem";
import ThreePIcon from '@mui/icons-material/ThreeP';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Groups2Icon from '@mui/icons-material/Groups2';
import currentContext from "../../../store/CurrentContext";
import {identity, must_be_defined} from "../../../types/Alias";
import currentInfoStore from "../../../store/CurrentInfoStore";
import UserType from "../../../types/UserType";
import {teamsRest} from "../../../net/rest/TeamsRest";
import TeamType from "../../../types/TeamType";
import teamStore from "../../../store/TeamStore";
import {TypesOfUsers} from "../../../store/Enumerations";
import usersStore from "../../../store/UsersStore";


const LeftPanel = observer((props: {currentMenu:MyMenuItemType, setCurrentmenu: (value: MyMenuItemType) => void}) => {

    const {t} = useTranslation();
    const {currentMenu, setCurrentmenu} = props;
    const [refresh, setRefresh] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);



    function updateTeamsAndUserTeams(next?: () => void) {
        setLoading(true);
        teamsRest.getTeams({
            sucess: (data: any): void => {
                data.content.forEach((value: TeamType) => {
                    teamStore.addTeam(value);
                });
                next ? next() : setLoading(false);
            }, error: (data: any): void => {
                setLoading(false);
                currentInfoStore.setInfo("Current user teams " + data, "ERROR");
            }
        }
            , [["size", "999999"]]);
    }


    function updateUsersAsVar(typeOfuser: TypesOfUsers, next?: () => void) {
        usersStore
            .clearSelection()
            .deleteUsersBy((value: UserType) => value.usertypeid === typeOfuser);

        usersRest.getUsers({
            sucess: (data: any) => {
                data.content.forEach((value: UserType) => {
                    usersStore.addUser(value);
                })
                next ? next() : setLoading(false);
            },
            error: (data: any) => {
                setLoading(false);
                currentInfoStore.setInfo("Type of users " + typeOfuser + " " + data, "ERROR");

            }
        }, [["usertypeid", typeOfuser], ["size", "999999"]]);
    }

    function updateCurrentUsers() {
        currentContext.User = usersStore.getUserByID(currentContext.UserID);
        setLoading(false);
    }
    //SyncUser

    React.useEffect(() => {
        updateTeamsAndUserTeams(
            () => updateUsersAsVar(TypesOfUsers.Agents, () => updateUsersAsVar(TypesOfUsers.Bots, () => updateCurrentUsers()))
        );
        return () => {

        };
    }, [refresh]);


    return (<>

        <Sheet
            sx={{
                width: 150,
                height: 'calc(100dvh - var(--Header-height))',
                display: 'flex',
                flexDirection: 'column',

            }}
        >
            <ListDecorator>
                <List size="sm" >

                    <MyMenuItem item={{mtype: "ITEM", mkey: "ALL"}} capt={t("ChatsAll")}
                        current={currentMenu} call={setCurrentmenu}
                        icon={<ForumIcon />} />
                    <MyMenuItem item={{mtype: "ITEM", mkey: "MY"}} capt={t("ChatsMy")}
                        current={currentMenu} call={setCurrentmenu}
                        icon={<ThreePIcon />} />

                    <ListDivider />
                    <MyMenuItem item={{mtype: "ITEM", mkey: "NEW"}} capt={t("ChatsNew")}
                        current={currentMenu} call={setCurrentmenu}
                        icon={<AnnouncementIcon />} />
                    <ListDivider />


                    <ListItem nested>
                        <ListSubheader variant="plain" >{t("ChatsTeams")}</ListSubheader>

                        {loading && (
                            <CircularProgress color="primary" />
                        )}
                        {(!loading) && (currentContext.User !== undefined) && (currentContext.User?.teams.length > 0) &&
                            (<>
                                <List>
                                    {
                                        currentContext.User?.teams.map((value: must_be_defined) => {
                                            return (
                                                <MyMenuItem item={{mtype: "KEY", mkey: value.teamid}} capt={value.teamds}
                                                    current={currentMenu} call={setCurrentmenu}
                                                    icon={<Groups2Icon />} />
                                            )
                                        })
                                    }
                                </List>
                            </>)
                        }
                    </ListItem>



                    <ListDivider />
                    <MyMenuItem item={{mtype: "ITEM", mkey: "ARH"}} capt={t("ChatsArh")}
                        current={currentMenu} call={setCurrentmenu}
                        icon={<ArchiveIcon />} />
                </List>
            </ListDecorator>
        </Sheet>

    </>);
});
export {LeftPanel};

