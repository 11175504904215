import {observer} from "mobx-react-lite";

import {useTranslation} from "react-i18next";
import {Box, ButtonGroup, Chip, CircularProgress, dialogActionsClasses, IconButton, Input, List, ListDivider, ListItem, ListItemButton, ListItemContent, ListItemDecorator, ModalDialogProps, Sheet, Stack, TextField, Typography} from "@mui/joy";

import {ListDecorator} from "../../../components/ListDecorator";

import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import MyDialogItem from "./components/MyDialogItem";
import dialogsStore from "../../../store/DialogsStore";
import {DialogTypeC} from "../../../types/DialogType";
import {identity, ustring} from "../../../types/Alias";
import {EditDialog, EditDialogDescriptor, initEditDialogDescriptor} from "../../../components/EditDialog";
import * as React from 'react';
import {DialogStatus, Priority, TypesOfUsers} from "../../../store/Enumerations";
import usersStore from "../../../store/UsersStore";
import UserType from "../../../types/UserType";
import {MyMenuItemType} from "./components/MyMenuItem";
import currentContext from "../../../store/CurrentContext";
import teamStore from "../../../store/TeamStore";
import TeamType from "../../../types/TeamType";
import SyncUserSearch from "./components/SyncUserSearch";
import {dialogRest} from "../../../net/rest/DialogRest";
import currentInfoStore from "../../../store/CurrentInfoStore";


import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';



function dateDefInput() {
    const date = new Date();
    date.setDate(date.getDate() - (process.env.REACT_APP_OFFSET_DAYS ? Number(process.env.REACT_APP_OFFSET_DAYS) : 3));
    const year = date.getFullYear();
    const month =
        (date.getUTCMonth() + 1).toString().length < 2
            ? `0${date.getUTCMonth() + 1}`
            : date.getUTCMonth() + 1;
    const day =
        date.getUTCDate().toString().length < 2
            ? `0${date.getUTCDate()}`
            : date.getUTCDate();
    return `${year}-${month}-${day}`;
}


type Options = {
    dialogid?: identity | undefined,
    userid?: string | undefined,
    username?: string | undefined,
    teamid?: identity | undefined,
    assignedid?: identity | undefined,
    priorityid?: identity | undefined,
    statusid?: identity | undefined,
    closedreasonid?: identity | undefined,
    dialogtags?: string | undefined,
    offsetdate: string,
    sortfield: string,
    sortorder: string,
    size: number,
}

type Pagination = {
    page: number,
    pagecount: number
}


const MiddlePanel = observer((props: {currentMenu: MyMenuItemType}) => {


    const {t} = useTranslation();

    const [loading, setLoading] = React.useState<boolean>(false);

    const [userSearchLayout, setUserSearchLayout] =
        React.useState<ModalDialogProps['layout'] | undefined>(undefined);
    const [userSearchLocator, setUserSearchLocator] =
        React.useState<{partValue: string, pushback: (code: string, value: string) => void}>
            ({partValue: "", pushback: (code: string, value: string) => {}});

    function getLayout(): (ModalDialogProps['layout'] | undefined) {
        return userSearchLayout;
    }

    function setLayout(layout: ModalDialogProps['layout'] | undefined) {
        setUserSearchLayout(layout);
    }


    const [options, setOptions] = React.useState<Options>();
    function getOptions() {
        return options || reOption(props.currentMenu);
    }
    function getOptionsFrom(opts: Options | undefined) {
        return opts || reOption(props.currentMenu);
    }
    function optEmptyToUndefided(value: string | undefined) {
        if ((value !== undefined) && (value.trim().length < 1)) {
            return undefined;
        } else {
            return value;
        }
    }
    const [pagination, setPagination] = React.useState<Pagination>();
    function getPagination() {
        return pagination || {page: 1, pagecount: 1};
    }
    function getPaginationInit() {
        return {page: 1, pagecount: 1};
    }
    function getPaginationFrom(p: any | undefined, pc: any | undefined) {
        return {
            page: p ? Number(p) : 1,
            pagecount: pc ? Number(pc) : 1
        }
    }




    function getUserLocator(): string {
        return userSearchLocator.partValue;
    }

    function setTargetUser(id: identity, name: string) {
        userSearchLocator.pushback(id, name);

    }

    function reOption(currentMenu: MyMenuItemType): Options {
        return {
            size: options ? options.size : 10,
            sortfield: "offsetdate",
            sortorder: "DESC",
            offsetdate: dateDefInput(),
            ...(currentMenu.mtype === "ITEM" && currentMenu.mkey === "MY")
                ? {assignedid: currentContext.UserID} : {},
            ...(currentMenu.mtype === "ITEM" && currentMenu.mkey === "ARH")
                ? {statusid: DialogStatus.Close} : {statusid: DialogStatus.Open},
            ...(currentMenu.mtype === "KEY") ? {teamid: currentMenu.mkey} : {},

        }
    }



    //Dialogs state
    const [dlgDescriptor, setDlgDescriptor] = React.useState<EditDialogDescriptor>(initEditDialogDescriptor(handlResultDialog));

    //Dialogs close
    function closeDialog() {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            return {anchor: undefined, visible: false, elements: [], handlResult: reset.handlResult};
        });
    }

    //Dialogs show
    function showDialog() {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            reset.visible = true;
            return {...reset};
        });
    }


    //Dialogs process result
    function handlResultDialog(anchor: identity | undefined, isapplied: boolean, result?: Map<string, ustring>) {
        closeDialog();
        if (isapplied) {
            switch (anchor) {
                case "SET":

                    const newOption: Options = {
                        ...getOptions()
                        , ...result?.has("teamid") ? {teamid: optEmptyToUndefided(result.get("teamid"))} : {}
                        , ...result?.has("assignedid") ? {assignedid: optEmptyToUndefided(result.get("assignedid"))} : {}
                        , ...result?.has("priorityid") ? {priorityid: optEmptyToUndefided(result.get("priorityid"))} : {}
                        , ...result?.has("offsetdate") ? {offsetdate: optEmptyToUndefided(result.get("offsetdate"))} : {}
                        , ...result?.has("sortfield") ? {sortfield: optEmptyToUndefided(result.get("sortfield"))} : {}
                        , ...result?.has("sortorder") ? {sortorder: optEmptyToUndefided(result.get("sortorder"))} : {}

                        , ...result?.has("userid") ? {userid:result.get("userid")} : {}
                        , ...(result?.get("userid") && result?.has("username")) 
                          ? {username: optEmptyToUndefided(result.get("username"))} : {}


                    }
                    if (result?.has("size") && result.get("size")) {
                        newOption.size = Number(result.get("size"));
                    }
                    
                    setOptions(newOption);
                    loadDialogs(reguestDialogs(newOption, getPagination(), props.currentMenu));
                    break;

                default:
                    break;
            }
        }
    }

    function reguestDialogs(opt: Options, pgn: Pagination, currentMenu: MyMenuItemType): [string, string][] {

        const queryparams: [string, string][] = [];

        queryparams.push(["size", String(opt.size)]);
        queryparams.push(["page", String(pgn.page-1)]);
        queryparams.push(["statusid", String(opt.statusid)]);

        if (opt.assignedid)
            queryparams.push(["assignedid", String(opt.assignedid)])
        if (opt.teamid)
            queryparams.push(["teamid", String(opt.teamid)])

        if (opt.offsetdate)
        {
            queryparams.push(["offsetdate", String(Math.floor(new Date(opt.offsetdate).getTime()/1000))]);
        }
        if (opt.dialogid)
            queryparams.push(["dialogid", String(opt.dialogid)]);
        if (opt.userid)
            queryparams.push(["userid", String(opt.userid)]);
        if (opt.priorityid)
            queryparams.push(["priorityid", String(opt.priorityid)]);
        if (opt.closedreasonid)
            queryparams.push(["closedreasonid", String(opt.closedreasonid)]);
        if (opt.dialogtags)
            queryparams.push(["dialogtags", String(opt.dialogtags)]);
        if (opt.sortfield)
            queryparams.push(["sortfield", String(opt.sortfield)]);
        if (opt.sortorder)
            queryparams.push(["sortorder", String(opt.sortorder)]);

        return queryparams;
    }


    function loadDialogs(queryparams: [string, string][]) {
        setLoading(true);
        dialogRest.getDialogs({
            sucess: (data: any) => {
                setLoading(false);
                setPagination(
                    getPaginationFrom(Number(data.pagenumber)+1, data.totalpages)
                );
                dialogsStore.clear();
                data.content.forEach((value: DialogTypeC) => {
                    dialogsStore.addDialog(value);
                })

            },
            error: (data: any) => {
                setLoading(false);
                setPagination(getPaginationInit());
                currentInfoStore.setInfo("Dialogs " + data, "ERROR");

            }
        }, queryparams);

    }

    React.useEffect(() => {
        const opt: Options = reOption(props.currentMenu);
        setOptions(opt);
        loadDialogs(reguestDialogs(opt, getPaginationInit(), props.currentMenu));
        return () => {

        };
    }, [props.currentMenu]);


    function generateFilter() {

        dlgDescriptor.title = t("Filter");
        dlgDescriptor.orientation = "horizontal";
        dlgDescriptor.elements = [
            {
                format: "INPUT", key: "username", search_key: "userid", title: t("User"), sxLable: {minWidth: 100}, sxInput: {minWidth: 150},
                search: (value: ustring, pushback: (code: string, value: string) => void) => {
                    setUserSearchLocator({partValue: value ? value : "", pushback: pushback});
                    setLayout('center');

                },
                currentValue: options?.username ,
                currentSearchValue: options?.userid
            }];
        if (!(props.currentMenu.mtype === "ITEM" && props.currentMenu.mkey === "MY"))
            dlgDescriptor.elements.push(
                {
                    format: "SELECT", key: "assignedid", title: t("Agent"), sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                    currentValue: options?.assignedid ? options.assignedid : "",
                    source: [
                        {code: "", value: "---"},
                        ...usersStore.Display((value) => value.usertypeid === TypesOfUsers.Agents).map((user: UserType) => {
                            return {code: user.userid, value: user.nameds}
                        }
                        )]
                });

        if (!(props.currentMenu.mtype === "KEY"))
            dlgDescriptor.elements.push(
                {
                    format: "SELECT", key: "teamid", title: t("Team"), sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                    currentValue: options?.teamid ? options.teamid : "",
                    source: [
                        {code: "", value: "---"},
                        ...teamStore.getTeams().map((team: TeamType) => {
                            return {code: team.teamid, value: team.teamds}
                        }
                        )]
                });

        dlgDescriptor.elements.push(
            {
                format: "SELECT", key: "priorityid", title: t("Priority"), sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                currentValue: options?.priorityid ? options.priorityid : "",
                source: [
                    {code: "", value: "---"},
                    {code: Priority.Cricical, value: t("en_priority_" + Priority.Cricical)},
                    {code: Priority.Hight, value: t("en_priority_" + Priority.Hight)},
                    {code: Priority.Medium, value: t("en_priority_" + Priority.Medium)},
                    {code: Priority.Low, value: t("en_priority_" + Priority.Low)},

                ]
            },
        );
        dlgDescriptor.elements.push(
            {
                format: "DATE", key: "offsetdate", title: t("Date"), sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                currentValue: options?.offsetdate ? options.offsetdate : dateDefInput()
            });
        dlgDescriptor.elements.push(
            {
                format: "SELECT", key: "sortfield", title: t("Sort"),
                sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                currentValue: options?.sortfield ? options.sortfield : "offsetdate",
                source: [
                    {code: "offsetdate", value: "date"},
                    {code: "dialogid", value: "dialog"},
                    {code: "userid", value: "user"},
                    {code: "teamid", value: "team"},
                    {code: "assignedid", value: "assignee"},
                    {code: "priorityid", value: "priority"},
                    {code: "statusid", value: "status"},
                ]
            }
        );
        dlgDescriptor.elements.push(
            {
                format: "SELECT", key: "sortorder", title: t("Order"), sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                currentValue: options?.sortorder ? options.sortorder : "DESC",
                source: [
                    {code: "ASC", value: "ASC"},
                    {code: "DESC", value: "DESC"},

                ]
            },
        );
        dlgDescriptor.elements.push(
            {
                format: "SELECT", key: "size", title: t("rowCount"), sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                currentValue: options?.size ? String(options.size) : "10",
                source: [
                    {code: "10", value: "10"},
                    {code: "20", value: "20"},
                    {code: "50", value: "50"},
                    {code: "70", value: "70"},
                    {code: "100", value: "100"},
                    {code: "200", value: "200"},
                    {code: "500", value: "500"},

                ]
            },
        );

        dlgDescriptor.anchor = "SET";
        showDialog();

    }

    function pageValue(delta: number, n?: number) {

        const pag = {...getPagination()};
        const nn = (n) ? (n + delta) : pag.page + delta;
        pag.page = nn <= 0 ? 1 : ((nn > pag.pagecount) ? pag.pagecount : nn)
        setPagination(pag);
        loadDialogs(reguestDialogs(getOptions(), pag, props.currentMenu));


    }



    return (<>

        <Sheet
            sx={{
                width: 300,
                height: 'calc(100dvh - var(--Header-height))',
                display: 'flex',
                flexDirection: 'column',

            }}
        >



            <Sheet
                sx={{
                    borderRadius: 'md',
                    borderStyle: "solid",
                    borderColor: "neutral.softHoverBg",
                    borderWidth: 1,
                    backgroundColor: 'neutral.softBg',
                    p: 1,
                    ///maxHeight: 'var(--Panel-height)',
                }}
            >
                <Stack direction="row" spacing={0.5}>
                    <IconButton size="sm" color="primary" variant="outlined"
                        onClick={() => {generateFilter()}}>
                        <FilterAltIcon />
                    </IconButton>

                    <IconButton size="sm" onClick={() => pageValue(-999999)} >
                        <KeyboardDoubleArrowLeftIcon sx={{fontSize: "16"}} />
                    </IconButton>
                    <IconButton size="sm" onClick={() => pageValue(-1)} >
                        <KeyboardArrowLeftIcon sx={{fontSize: "16"}} />
                    </IconButton>
                    <Input size="sm" type="number" variant="outlined" sx={{minWidth: 50}}
                        value={pagination ? pagination.page : 1}
                        onChange={(event) => pageValue(0, Number(event.target.value))}
                        endDecorator={<Chip>{pagination ? pagination.pagecount : 1}</Chip>}
                    />
                    <IconButton size="sm" onClick={() => pageValue(1)} >
                        <KeyboardArrowRightIcon sx={{fontSize: "16"}} />
                    </IconButton>
                    <IconButton size="sm" onClick={() => pageValue(999999)} >
                        <KeyboardDoubleArrowRightIcon sx={{fontSize: "16"}} />
                    </IconButton>

                </Stack>
            </Sheet>



            {loading ? (
                <>
                    <CircularProgress color="primary" />
                </>
            )
                :
                (
                    <ListDecorator>
                        <List size="sm" >
                            {dialogsStore.Display().map(
                                (dialog: DialogTypeC) => {
                                    return (
                                        <MyDialogItem dialog={dialog} />
                                    );
                                }
                            )}

                        </List>
                    </ListDecorator>
                )
            }

        </Sheet >

        <EditDialog descriptor={dlgDescriptor} />
        <SyncUserSearch
            getUserLocator={getUserLocator}
            setTargetUser={setTargetUser}
            getLayout={getLayout}
            setLayout={setLayout}
        />

    </>);
});
export default MiddlePanel;
