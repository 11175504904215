import {ListItem, ListItemButton, ListItemContent, ListItemDecorator, Typography} from "@mui/joy";
import {identity} from "../../../../types/Alias";

export type MyMenuItemType={
    mtype: "ITEM"|"KEY", 
    mkey:  "ALL"|"MY"|"NEW"|"ARH"|identity
}


export default function MyMenuItem(props: {
    item: MyMenuItemType,
    capt: string,
    current: MyMenuItemType,
    call: (value: MyMenuItemType) => void,
    icon: React.ReactElement
}) {
    return (
        <ListItem
            variant="plain"
            key={props.item.mkey}
            sx={{backgroundColor: ((props.current.mkey === props.item.mkey) ? "primary.softHoverBg" : "inherit")}}>
            <ListItemButton variant="plain"
                onClick={() => props.call(props.item)}
                color={(props.current.mkey === props.item.mkey) ? "primary" : "neutral"}
            >
                <ListItemDecorator >{props.icon}</ListItemDecorator>
                <ListItemContent> <Typography color="neutral" level="body-sm"> {props.capt}</Typography></ListItemContent>
            </ListItemButton>


        </ListItem>
    );
}