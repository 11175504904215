import currentContext from "../../store/CurrentContext";
import lo from "../../store/Logging";

type RestCallback = {
    envoy?: any;
    sucess: (data: any, envoy?: any) => void;
    error: (data: any, envoy?: any) => void;
}

class GenericClient {


    public prepareCallHttp(
        url: string,
        method: string,
        headers: [string, string][],
        credentials?: RequestCredentials,
        body?: BodyInit,
        queryparams?: [string, string][]
    ): {prep_params: RequestInit, prep_url: string} {
        if (currentContext.Token) {
            headers.push(["x-csrf-token", currentContext.Token]);
        }
        const params: RequestInit = {
            method: method,
            headers: headers
        };
        if (credentials)
            params.credentials = credentials;
        if (body)
            params.body = body;

        let query: URLSearchParams = new URLSearchParams();
        if (queryparams) {
            for (let i = 0; i < queryparams.length; i++) {
                query.append(queryparams[i][0], queryparams[i][1]);
                //encodeURIComponent(queryparams[i][1]));
            }
            url = url + "?" + query.toString();
        }

        try{
            if (lo.RestEnable)
            {
                lo.addUrl(headers,url);
            }
        }
        catch(e){}
        return {prep_params: params, prep_url: url};

    }


    public callHttp(url: string,
        method: string,
        headers: [string, string][],
        callback: RestCallback,
        credentials?: RequestCredentials,
        body?: BodyInit,
        queryparams?: [string, string][],
        resetToken?: boolean) {

        resetToken = (resetToken === undefined) ? false : true;
        const {prep_params, prep_url} = this.prepareCallHttp(
            url, method, headers, credentials, body, queryparams
        );

        fetch(prep_url, prep_params)
            .then(response => {
                if (response.ok) {
                    if (response.headers.has("x-csrf-token") && resetToken) {
                        currentContext.refreshToken(response.headers.get("x-csrf-token"));
                    }

                    if (response.headers.get("Content-Type")?.includes("application/json")) {
                        return response.text()
                    }
                }
                else {
                    throw new Error("CODE " + response.status + " " + (response.bodyUsed ? response.text : ""));
                }
                return JSON.parse("{\"data\":\"error\"}");

            })
            .then(data => {
                let dataJSON = undefined;
                try {
                    dataJSON = JSON.parse(data)
                }
                catch (gotErro) {
                    throw new Error(gotErro + " " + data)
                }
                callback.sucess(dataJSON, callback.envoy)
            }
            )
            .catch(error => {
                callback.error(error.message + " " + url, callback.envoy);
            })

    }

    // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL

    public callHttpPostFile(url: string,
        method: string,
        headers: [string, string][],
        callback: RestCallback,
        file: File,
        credentials?: RequestCredentials,
        queryparams?: [string, string][],
        resetToken?: false

    ) {

        try {
            const reader: FileReader = new FileReader();
            reader.onload = (ev: ProgressEvent<FileReader>) => {
                if (reader.result) {
                    let base64Encoded: string = reader.result as string;
                    headers.push(['Content-Length', Buffer.byteLength(base64Encoded, "base64") + ""]);
                    this.callHttp(url, method, headers, callback, credentials, reader.result as string, queryparams, resetToken);
                }
            };
            reader.onerror = (error) => {
                callback.error(`Error read ${file.name} ${error}`, callback.envoy);
            };
            if (file) {
                reader.readAsDataURL(file);
            }
        }
        catch (err) {
            callback.error(`Error load ${file.name} ${err}`, callback.envoy);
        }

    }


    public callHttpPostFile2(url: string,
        method: string,
        body_param: string,
        //headers: [string, string][],
        callback: RestCallback,
        file: File)
    //credentials?: RequestCredentials) 
    {
        const formData = new FormData();
        formData.append(body_param, file); // "content OR file" должно совпадать с именем параметра в вашем контроллере
        // Отправка данных на сервер
        fetch(url, {
            method: method,
            body: formData
        })
            .then(response => {
                if (response.ok)//200
                {
                    return response.json;
                }
                else {
                    throw new Error("Http Post File error: " + response.status);
                }
            }
            )
            .then(data => callback.sucess(data, callback.envoy))
            .catch(error => {
                callback.error(url + " " + method + " " + error.message, callback.envoy);
            })
    }


    public callHttpGetFile(
        url: string,
        method: string,
        callback: RestCallback) {
        fetch(url, {method: method})
            .then(response => {
                if (response.ok)//200
                {
                    const blob = response.blob();
                    return blob;
                }
                else {
                    throw new Error("Http Get File error: " + response.status);
                }
            }
            )
            .then(data => callback.sucess(data, callback.envoy))
            .catch(error => {
                callback.error(url + " " + method + " " + error.message, callback.envoy);
            })
    }


    public callHttpDelete(url: string,
        method: string,
        headers: [string, string][],
        callback: RestCallback,
        credentials?: RequestCredentials,
        body?: BodyInit,
        queryparams?: [string, string][]) {

        this.callHttp(url, method, headers, callback, credentials, body, queryparams);
    }


    public setUrl(path: string): string {
        return process.env.REACT_APP_URL_API + path;
    }
}


const genericClient = new GenericClient();


export {genericClient};
export type {RestCallback};
