
import currentContext from "../../store/CurrentContext";
import dialogsStore from "../../store/DialogsStore";
import lo from "../../store/Logging";
import {sockStateStore} from "../../store/SockStateStore";
import {track} from "../../types/Alias";
import MsgType from "../../types/MsgType";
import {dateToMs_String} from "../../utils";
import messageDispatcher from "./messageDispatcher";
declare const window: any;

export type ItemSocket = {
    msg: any;
    trackid?: undefined | track;
    tracking?: (state: "OK" | "ERROR", trackid?: undefined | track, tracktext?: string) => void;
}

let socket: WebSocket;
let max_attempts: number = (process.env.REACT_APP_MAX_ATTEMPT || 60000) as number;
let default_retry_ms: number = (process.env.REACT_APP_RETRY_MS || 3000) as number;
let sock_url = process.env.REACT_APP_URL_SOCK || "wss://helpmetest.pepsico.digital/ws-endpoint";
let ENV_ScannedRecon:"NEWQUEST"|"NEWMESSAGE"|"GENERALACKNOW"|"UPDDIALOG"|"NEWDIALOGACKNOW"|"UNKNOW";


function up(
    attempt: number,
    retry_ms: number = default_retry_ms as number
) {
    sockStateStore.setSockStateInfo(readyState(), attempt);
    socket = new WebSocket(sock_url);

    socket.addEventListener("open", event => {
        sockStateStore.setSockStateInfo(readyState(), attempt);
        attempt = 0;
        //Send HandShacke
        const msgValue: MsgType = {
            trackingid: "StrGUID",
            userid: currentContext.UserID,
            dialogid: "",
            messagetypeid: 3,
            content: "",
            replymessageid: undefined,
            senttime: dateToMs_String(new Date()),
        };
        let ItemSocet: ItemSocket = {
            msg: msgValue
        }
        send(ItemSocet);
    });

    socket.addEventListener("message", (event: MessageEvent<string>) => {
        sockStateStore.setSockStateInfo(readyState(), attempt);
        GetTypeMSG(JSON.parse(event.data));
        messageDispatcher(event.data,ENV_ScannedRecon);
        try {
            if (lo.WsEnable) {
                lo.addWs("IN", event.data);
            }
        }
        catch (e) {}
    });

    socket.addEventListener("close", event => {
        sockStateStore.setSockStateInfo(readyState(), attempt);
        setTimeout(function () {
            if (attempt < max_attempts) {
                up(attempt + 1, retry_ms);
            }
        }, retry_ms);
    });

    socket.addEventListener("error", (event) => {
        sockStateStore.setSockStateInfo(readyState(), attempt);
    });

}

function isReady() {
    return (readyState() === WebSocket.OPEN)
        && (window.surplusDummyCheck());
}

function readyState(): number {

    return socket?.readyState || -1;

}


function send(item: ItemSocket) {
    if (isReady()) {
        try {
            socket.send(JSON.stringify(item.msg));
            inform_tracker("OK", item);
        }
        catch (err) {
            inform_tracker("ERROR", item, err as string);
        }
    }
    else {
        enqueue(item);
    }
    try {
        if (lo.WsEnable) {
            lo.addWs("OUT", JSON.stringify(item.msg));
        }
    }
    catch (e) {}
}

function inform_tracker(state: "OK" | "ERROR", {trackid, tracking}: ItemSocket, text?: string) {
    try {
        if (tracking)
            tracking(state, trackid, text);
    }
    catch (err) {
    }

}

const queue: Array<ItemSocket> = new Array<ItemSocket>();

function enqueue(item: ItemSocket) {
    queue.push(item);
    //sockStateStore.count = queue.length;
}

function dequeue(handler: (item: ItemSocket) => boolean) {
    if (queue.length > 0) {
        if (handler(queue.at(0) as ItemSocket)) {
            queue.shift();
            //sockStateStore.count = queue.length;
        }
    };
}

export function auto(timeout: number = default_retry_ms) {
    if (isReady()) {
        while (queue.length > 0) {
            //sockStateStore.count = queue.length;
            // eslint-disable-next-line no-loop-func
            dequeue((item: ItemSocket) => {
                if (isReady())
                    try {
                        socket.send(JSON.stringify(item.msg));
                        // inform_tracker("OK",item);

                    }
                    catch (err) {
                        // inform_tracker("ERROR",item, err as string);
                        return false;
                    }
                return true;
            });
        }
    }
    setTimeout(() => auto(timeout), timeout);
}

function GetTypeMSG(data:any){
    if (data.messagetypeid !== undefined) {
        if ((data.buttons !== undefined) && (data.buttons.buttons.length > 0)) {
            return ENV_ScannedRecon="NEWQUEST";
        }
        else {
            return ENV_ScannedRecon="NEWMESSAGE";
        }
    }
    else {
        if (data.messageid !== undefined) {
            return ENV_ScannedRecon="GENERALACKNOW";
        }
        else {
            if (data.dialogid !== undefined) {
                if (data.userid !== undefined) {
                    return ENV_ScannedRecon="UPDDIALOG";
                }
                else {
                    return ENV_ScannedRecon="NEWDIALOGACKNOW";
                }
            }
            else {
                return ENV_ScannedRecon="UNKNOW";
            }

        }
    }
}

// let specificStatusCodeMappings = {
//     '1000': 'Normal Closure',
//     '1001': 'Going Away',
//     '1002': 'Protocol Error',
//     '1003': 'Unsupported Data',
//     '1004': '(For future)',
//     '1005': 'No Status Received',
//     '1006': 'Abnormal Closure',
//     '1007': 'Invalid frame payload data',
//     '1008': 'Policy Violation',
//     '1009': 'Message too big',
//     '1010': 'Missing Extension',
//     '1011': 'Internal Error',
//     '1012': 'Service Restart',
//     '1013': 'Try Again Later',
//     '1014': 'Bad Gateway',
//     '1015': 'TLS Handshake'
// };

// function getStatusCodeString(code:number) {
//     if (code >= 0 && code <= 999) {
//         return '(Unused)';
//     } else if (code >= 1016) {
//         if (code <= 1999) {
//             return '(For WebSocket standard)';
//         } else if (code <= 2999) {
//             return '(For WebSocket extensions)';
//         } else if (code <= 3999) {
//             return '(For libraries and frameworks)';
//         } else if (code <= 4999) {
//             return '(For applications)';
//         }
//     }
//     if (typeof(specificStatusCodeMappings[code]) !== 'undefined') {
//         return specificStatusCodeMappings[code];
//     }
//     return '(Unknown)';
// }


export {up, send}
