import {RestCallback, genericClient} from "./GenericClient";

class AttachRest {

    api:string=genericClient.setUrl( "/attachments");
    
    // get all docs - no content
    public getDocs(callback:RestCallback)
    {
        //https://qna.habr.com/q/1021030 
        //['Content-Type', 'application/json'],["Access-Control-Allow-Origin", "*"]
        genericClient.callHttp(this.api, "GET", [], callback);
    }

    // add doc 
    public postDoc (callback:RestCallback, file: File,msgID:string)
    {
        genericClient.callHttpPostFile2(this.api+"/"+msgID, "POST",'Content-Length', callback, file);
    }

    // delete doc
    public deleteDoc(callback:RestCallback, DocID:string, body:string)
    {
        genericClient.callHttpDelete(this.api + "/" + DocID, "DELETE", [], callback, undefined);
    }
   
    
}
const attachRest = new AttachRest();

export {attachRest};
