import {RestCallback, genericClient} from "./GenericClient";

class TeamsRest {
    

    api:string=genericClient.setUrl( "/api/teams");

    public getTeams(callback:RestCallback,queryparams?:[string, string][])
    {
        genericClient.callHttp(this.api,"GET",[['Content-Type', 'application/json']],callback,undefined,undefined,queryparams);
    }

    public postTeams(callback:RestCallback,body:string)
    {

        genericClient.callHttp(this.api,"POST",[['Content-Type', 'application/json']],callback,undefined,body);

    }

    public DeleteTeams(callback:RestCallback, TeamsID:string, body:string)
    {
        genericClient.callHttpDelete(this.api+"/"+TeamsID,"DELETE",[],callback,undefined);

    }

    public ChangeTeams(callback:RestCallback, body:string)
    {
        genericClient.callHttp(this.api,"PATCH",[['Content-Type', 'application/json']],callback,undefined,body);

    }

    public AddMembersTeams(callback:RestCallback,body:string)
    {
        genericClient.callHttp(this.api+"/addmembers","PATCH",[['Content-Type', 'application/json']],callback,undefined,body);

    }

    public DeleteMembersTeams(callback:RestCallback,body:string)
    {
        genericClient.callHttp(this.api+"/delmembers","PATCH",[['Content-Type', 'application/json']],callback,undefined,body);

    }



}
const teamsRest = new TeamsRest();

export {teamsRest};