export type identity=string;
export type flag=boolean;
export type uri=string;
export const identity_null:identity="";
export type track=string;
export type ustring=string|undefined;
export type nustring=string|undefined|null;
export type must_be_defined=any;
export type datetime=any;


export {}