import * as React from 'react';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog, {ModalDialogProps} from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';

type MsgBoxType = {
    mutaing: (re?: {caption: string, text: string, open: boolean}) => {caption: string, text: string, open: boolean};
}

export default function MsgBox(props: MsgBoxType) {
    let mutabled: {caption: string, text: string, open: boolean} = props.mutaing();
    return (
        <React.Fragment>
            <Modal
                open={mutabled.open}
                onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                    ;
                    props.mutaing({
                        caption: mutabled.caption,
                        text: mutabled.text, open: false
                    });
                }}
            >
                <ModalDialog size="sm">
                    <ModalClose />
                    <DialogTitle>{mutabled.caption}</DialogTitle>
                    <DialogContent>{mutabled.text}</DialogContent>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}