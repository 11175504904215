import {observer} from "mobx-react-lite";

import {Box, Button, CircularProgress, Stack} from "@mui/joy";

import {MsgBubble} from "./MsgBubble";
import AvatarWithStatus from "../../../../components/AvatarWithStatus";
import msgStore from "../../../../store/MsgStore";
import { MsTodate } from "../../../../utils";
import { KeyboardDoubleArrowUp } from "@mui/icons-material";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import React from "react";
import dialogsStore from "../../../../store/DialogsStore";
import MsgType from "../../../../types/MsgType";


const MesgsBody = observer((props: {replyMessage:MsgType|undefined, setreplyMessage: (value: MsgType) => void}) => {
const [lightLoading, setLightLoading] = useState<{show: boolean, text?: string}>({show: false});
const [VisibleButton,setVisibleButton] = useState<boolean>(false);
function AddOldMsg(){
    setLightLoading({show: true});
    msgStore.BackLoad();
    setLightLoading({show: false});
}

    React.useEffect(() => {
        if (msgStore.VisibleButton()) {
            setVisibleButton(true);
        } else {
            setVisibleButton(false);
        }
        return () => {

        };
    }, [VisibleButton, msgStore.VisibleButton()]);

    return (
        <>
            <Box
                sx={{
                    // height: "100dvh",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center", justifyContent: "center"
                }}
            >
                {VisibleButton ?(
                <Button 
                variant="plain"
                color="neutral"
                sx={{ fontSize: 12 }}
                onClick={AddOldMsg}
            > { (lightLoading.show) ?
                (<><CircularProgress sx={{ zoom: "50%" }} />
                    Загрузка ранних сообщений...</>) :
                (<><KeyboardDoubleArrowUp />
                    {(lightLoading.text) ? (<>lightLoading.text</>)
                        : (<>Загрузить ранние сообщения</>)}
                </>)

                    }</Button>):(<></>)}
            </Box>
            {msgStore.Display().map((message: any) => {
                const user=message.sender||"No name";
                const isme = message.isme;
                const msgDt = MsTodate(message.senttime).toLocaleString("ru-RU");
                
                return (
                    <Stack
                        key={message.trackingid}
                        direction="row"
                        spacing={2}
                        //flexDirection={isme ? 'row-reverse' : 'row'}
                        //flexDirection={'row'}
                    >
                        {!isme ? (
                            <AvatarWithStatus
                                //online={(sockStateStore.state==1)}
                                src={user?.avatar}
                                role={user?.role}
                            />
                        ) :
                            (
                                <AvatarWithStatus
                                    //online={user?.online}
                                    //src={user?.avatar}
                                    role={user?.role}
                                />
                             
                            )
                        }
                        <MsgBubble me={isme} message={message} username={user} msgDt={msgDt} anchor={0} setreplyMessage={props.setreplyMessage}/>
                    </Stack>
                    
                );
            })}
            
        </>

    );
});
export default MesgsBody;