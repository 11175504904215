
function isStringTestJson(a: any) {
    try {
        JSON.parse(a);
    } catch (e) {
        return false;
    }
    return true;
}

function castJsonToStringOrDes(a: any): string {
    if (isStringTestJson(a)) {
        return JSON.stringify(a);
    }
    else {
        let str: string = "";
        for (const key in a) {
            str = str + key + "=" + a[key];
        }
        return str;
    }
}

function dateReFormat(value: string, inf: string, outf: string): string {
    let y = inf.indexOf("yyyy"), ysize = 4;
    if (y === -1) {
        y = inf.indexOf("yy");
        ysize = 2;
    }
    let m = inf.indexOf("mm");
    let d = inf.indexOf("dd");
    let h = inf.indexOf("HH");
    let mi= inf.indexOf("MM");
    let s= inf.indexOf("SS")
    if (y > -1) {
        outf=outf.replace(((ysize > 2) ? "yyyy" : "yy"),value.substring(y, y+ysize));
    }
    if (m > -1) {
        outf=outf.replace("mm",value.substring(m, m+2));
    }
    if (d > -1) {
        outf=outf.replace("dd",value.substring(d, d+2));
    }
    if (h > -1) {
        outf=outf.replace("HH",value.substring(h, h+2));
    }
    if (mi > -1) {
        outf=outf.replace("MM",value.substring(mi, mi+2));
    }
    if (s > -1) {
        outf=outf.replace( "SS",value.substring(s, s+2));
    }
    return outf;
}

//Convert date to epoch or unix timestamp in TypeScript
function dateToMs_String(data: Date): string {
    let date_String: string = (data.getTime()/1000).toFixed().toString();
    return date_String;
  }

  //Convert epoch or Unix timestamp to date in TypeScript
function MsTodate(data: number): Date {
    let date_String: Date = new Date(data*1000);
    return date_String;
  }



export {isStringTestJson, castJsonToStringOrDes,dateReFormat, dateToMs_String, MsTodate}

