
import {RestCallback, genericClient} from "./GenericClient";

class TestCORS {


    public getDogs(api:string,callback:RestCallback)
    {        genericClient.callHttp(api, "GET", [], callback);
    }

    
}
const testCORS = new TestCORS();

export {testCORS};
