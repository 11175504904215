import {FileCopy, Flag, Group, Info, IntegrationInstructions, KeyboardArrowRight, Person} from "@mui/icons-material";
import {Box, Button, ButtonGroup, CircularProgress, IconButton, List, ListItem, ListItemButton, ListItemContent, ListItemDecorator, ListSubheader, Sheet, Typography} from "@mui/joy";
import {observer} from "mobx-react-lite";
import docsStore from "../../../store/DocsStore";
import DocsType from "../../../types/DocsType";
import {StyledListItem} from "@mui/joy/ListItem/ListItem";
import * as React from 'react';
import {docsRest} from "../../../net/rest/DocsRest";
import currentInfoStore from "../../../store/CurrentInfoStore";
import {useTranslation} from "react-i18next";
import {identity, ustring, flag} from "../../../types/Alias";
import {EditDialog, EditDialogDescriptor, initEditDialogDescriptor} from "../../../components/EditDialog";
import {CaptionPanel} from "../../../components/CaptionPanel";
import {EditButtonsPanel} from "../../../components/EditButtonsPanel";
import {ListDecorator} from "../../../components/ListDecorator";


const LeftPanel = observer(() => {

    const {t} = useTranslation();
    const [refresh, setRefresh] = React.useState<boolean>(false);
    const [showStatusLeft, setShowStatusLeft] = React.useState<boolean>(true);

    //Dialogs state
    const [dlgDescriptor, setDlgDescriptor] = React.useState<EditDialogDescriptor>(initEditDialogDescriptor(handleResultDialog));
    //Dialogs close
    function closeDialog() {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            return {anchor: undefined, visible: false, elements: [], handlResult: reset.handlResult};
        });
    }
    //Dialogs show
    function showDialog() {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            reset.visible = true;
            return {...reset};
        });
    }

    function handleResultDialog(anchor: identity | undefined, isapplied: boolean, result?: Map<string, ustring>, linkedresult?: Map<string, Object>) {
        closeDialog();
        // TODO
        if (isapplied) {

            switch (anchor) {
                
                case "ADD": {
                    if (!linkedresult?.get("DocName")) {
                        currentInfoStore.setInfo("Введите имя документа", "ERROR");
                        break;
                    }
                    const file: File = linkedresult?.get("DocName") as File;
                    let newDocAudit = docsStore.getDocByName(file.name);
                    if (newDocAudit !== undefined) {
                        currentInfoStore.setInfo("Документ с именем " + file.name + " уже существует", "ERROR");
                        break;
                    }
                    setShowStatusLeft(true);

                    try{
                        docsRest.postDoc(
                        {
                            sucess: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                                docsStore.addDoc(
                                    {
                                        docid: data,
                                        filenameds: envoy.filenameds,
                                        content: undefined,
                                        contenturi: undefined,
                                        activefl: true
                                    }
                                )
                            },
                            error: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                                currentInfoStore.setInfo("Add Doc error  " + data, "ERROR");
                            },
                            envoy: {id: (new Date()).getTime() + "", filenameds: result?.get("DocName") || "NoName"}

                        },
                        file
                    );
                    }
                    catch (err: any) {
                        setShowStatusLeft(false);
                        currentInfoStore.setInfo(`Add Doc processing error: ${err.message}`, "ERROR");
                    }
                    break;
                }
    
                case "UPD": {
                    if (!linkedresult?.get("DocName")) {
                        currentInfoStore.setInfo("Для документа нет изменений", "ERROR");
                        break; 
                    }

                    let changeDocAudit = docsStore.getDocByName(result?.get("DocName"));
                    if (changeDocAudit !== undefined) {
                        currentInfoStore.setInfo("Документ с именем " + result?.get("DocName") + " уже существует", "ERROR");
                        break;
                    }

                    const updfile: File = linkedresult?.get("DocName") as File;
                    let updDocAudit = docsStore.getDocByName(updfile.name);

                    if (updDocAudit !== undefined) {
                        currentInfoStore.setInfo("Документ с именем " + updfile.name + " уже существует", "ERROR");
                        break;
                    };
                    const selectedDoc = docsStore.getDocByID(docsStore.SelectedIDDoc);
                    if (selectedDoc) {
                    setShowStatusLeft(true);

                    try{
                        docsRest.patchDoc(
                        {
                            sucess: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                                selectedDoc.filenameds = result?.get("DocName")||"NoName";
                            },
                            error: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                                currentInfoStore.setInfo("Update Doc error " + data, "ERROR");
                            },
                            envoy: {id: (new Date()).getTime() + "", filenameds: result?.get("DocName") || "NoName"}

                        },
                        selectedDoc.docid,
                        updfile
                    );
                    }
                    catch (err: any) {
                        setShowStatusLeft(false);
                        currentInfoStore.setInfo(`Update Doc processing error: ${err.message}`, "ERROR");
                    }                    
                }
                break;
                }

                case "DEL": {
                    const jsonDel = docsStore.getDocByID(docsStore.SelectedIDDoc);
                    docsRest.deleteDoc(
                        {
                            sucess: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                                    const selectedDocDelete = docsStore.getDocByID(docsStore.SelectedIDDoc);
                                    if (selectedDocDelete) {
                                        docsStore.deleteDoc(selectedDocDelete);
                                    }

                            },
                            error: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                                currentInfoStore.setInfo("Delete Doc error " + data, "ERROR");
                            },
                        },
                        docsStore.SelectedIDDoc, 
                        JSON.stringify(jsonDel)
                    );
                    break;
                }

                case "DNLD": {
                    const selectedDocDnld = docsStore.getDocByID(docsStore.SelectedIDDoc);
                    const dnldFileName = selectedDocDnld?.filenameds||"NoName";
                    docsRest.getDocFile(
                        {
                            sucess: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                                triggerDownload(data, dnldFileName);
                            },
                            error: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                                currentInfoStore.setInfo("Get Doc error " + data, "ERROR");
                            },
                        },
                        docsStore.SelectedIDDoc                        
                    );
                    break;
                }
                default: {
                    break;
                }
            }

        }
    }

    //Add document modal window
    function addRecord() {
        dlgDescriptor.title = t("Add_Title");
        dlgDescriptor.text = t("Add_Doc");
        dlgDescriptor.apply = t("Button_ADD");
        dlgDescriptor.cancel = t("Button_Cancel");
        dlgDescriptor.elements = [
            {format: "FILE", key: "DocName", title: t("Doc_Filename")},
        ];
        dlgDescriptor.anchor = "ADD";
        showDialog();
    }

    //Update
    function updateRecord() {
        const selectedDoc = docsStore.getDocByID(docsStore.SelectedIDDoc);
        
        if (selectedDoc) {
            dlgDescriptor.title = t("Edit_Title");
            dlgDescriptor.text = t("Edit_Doc");
            dlgDescriptor.apply = t("Button_change");
            dlgDescriptor.cancel = t("Button_Cancel");
            dlgDescriptor.elements = [{format: "FILE", key: "DocName", title: t("Doc_Filename"), currentValue: selectedDoc.filenameds}];    
            dlgDescriptor.anchor = "UPD";
            showDialog();
        }
    }

    //Delete
    function deleteRecord() {
        const selectedDoc = docsStore.getDocByID(docsStore.SelectedIDDoc);
        if (selectedDoc) {
            dlgDescriptor.title = t("Delete_Title");
            dlgDescriptor.cancel = t("Button_Cancel");
            dlgDescriptor.apply = t("Button_Delete");
            dlgDescriptor.text = `${t("Delete_Text")} ${selectedDoc.filenameds} ?`;
            dlgDescriptor.elements = [];
            dlgDescriptor.anchor = "DEL";
            showDialog();
        }
    }

    //Download
    function downloadFile() {
        const selectedDoc = docsStore.getDocByID(docsStore.SelectedIDDoc);
        if (selectedDoc) {
            dlgDescriptor.title = t("Download_Title");
            dlgDescriptor.cancel = t("Button_Cancel");
            dlgDescriptor.apply = t("Download_Button");
            dlgDescriptor.text = `${t("Download_Text")} ${selectedDoc.filenameds} ?`;
            dlgDescriptor.elements = [];
            dlgDescriptor.anchor = "DNLD";
            showDialog();
        }
    }

    function triggerDownload(blob: Blob, fileName: string): void {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
       }

    function success_processDocs(data: any): void {
        setShowStatusLeft(false);
        data.forEach((value: DocsType) => {
            docsStore.addDoc(value);
        })
    }
    function error_processDocs(data: any): void {
        setShowStatusLeft(false);
        currentInfoStore.setInfo("Docs " + data, "ERROR");

    }

    function updateDocs() {
        setShowStatusLeft(true);
        docsRest.getDocs({sucess: success_processDocs, error: error_processDocs});
    }

    React.useEffect(() => {
        updateDocs();
        return () => {
        };
    }, [refresh]);

    return (
        <>
            <Sheet
                sx={{
                    width: 300,
                    height: 'calc(100dvh - var(--Header-height))',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >

                <CaptionPanel caption={t("title_docs")}
                    status={() => showStatusLeft}
                    refresh={() => setRefresh(!refresh)} />


                <ListDecorator>
                    <List size="sm">

                        {docsStore.getDocs().map((value: DocsType) =>
                        (
                            <ListItem
                                variant={((docsStore.SelectedIDDoc === value.docid)) ? "outlined" : "plain"}
                                sx={{backgroundColor: (docsStore.SelectedIDDoc === value.docid) ? "primary.softHoverBg" : "inherit"}}
                                key={value.docid} >
                                <ListItemButton variant="plain"
                                    onClick={() => {
                                        docsStore.SelectedDSFileName = value.filenameds;
                                        docsStore.SelectedIDDoc = value.docid;
                                    }}>
                                    <ListItemDecorator><FileCopy /></ListItemDecorator>
                                    <ListItemContent>{value.filenameds} </ListItemContent>
                                    {(docsStore.SelectedIDDoc === value.docid) && (<KeyboardArrowRight />)}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </ListDecorator>
                <EditButtonsPanel add={addRecord} update={updateRecord} delete={deleteRecord} download={downloadFile}/>
            </Sheet>
            <EditDialog descriptor={dlgDescriptor} />
        </>

    );
});


export {LeftPanel};

