import {Sheet} from "@mui/joy";
import {observer} from "mobx-react";
import {LeftPanel} from "./LeftPanel";
import {ContentPanel} from "./ContentPanel";
import MiddlePanel from "./MiddlePanel";
import * as React from 'react';
import {identity} from "../../../types/Alias";
import {MyMenuItemType} from "./components/MyMenuItem";

const Layout = observer(() => {
    const [currentMenu, setCurrentmenu] = React.useState
        <MyMenuItemType>({mtype: "ITEM", mkey: "MY"});
    return (
        <>
            <Sheet
                sx={{
                    display: 'grid',
                    width: '100%',
                    mx: 'auto',
                    gridTemplateColumns: {
                        xs: 'minmax(min-content, min(10%, 100px)) minmax(min-content, min(10%, 100px)) 1fr',
                        sm: 'minmax(min-content, min(10%, 100px)) minmax(min-content, min(20%, 200px)) 1fr',
                        md: 'minmax(min-content, min(10%, 100px)) minmax(min-content, min(205, 250px)) 1fr',
                    }
                }}>
                <Sheet
                    sx={{
                        position: 'sticky',
                        zIndex: 100,
                        width: '100%',
                        top: 'var(--Header-height)',

                    }}
                >
                    <LeftPanel currentMenu={currentMenu} setCurrentmenu={setCurrentmenu} />


                </Sheet>
                <Sheet
                    sx={{
                        position: 'sticky',
                        zIndex: 100,
                        width: '100%',
                        top: 'var(--Header-height)',
                    }}
                >
                    <MiddlePanel currentMenu={currentMenu} />


                </Sheet>
                <Sheet
                    sx={{
                        height: "calc(100dvh - var(--Header-height))",
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <ContentPanel />
                </Sheet>

            </Sheet>
        </>

    );
});

export {Layout};
