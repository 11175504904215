import * as React from 'react';
import {CssVarsProvider} from '@mui/joy/styles';
import {Box, CssBaseline, Typography, CircularProgress} from '@mui/joy';
import {BaseStyles} from './BaseStylesUtils';
import HeadBar from './head/HeadBar';
import BodyLayout from './body/BodyLayout';

import BaseInfo from './BaseInfo';
import __fakedata__ from '../store/_use_case_data';
import currentContext from '../store/CurrentContext';
import {genericClient} from '../net/rest/GenericClient';
import ReportIcon from '@mui/icons-material/Report';
import { up } from '../net/socket/genericSocket';


export default function BaseLayout() {



  const [welcomeState, setWelcomeState] = React.useState<{s: string, run: boolean, barrier: boolean}>
    ({s: "Подключение и загрузка пользователя ...", run: true, barrier: true});
  const [openSocket, setOpenSocket] = React.useState<boolean>(true);


  function initsystem(): Error | undefined {
    try {
      let er: Error | undefined = currentContext.loadCookieUser();
      if (er) throw er;
      if (!currentContext.isSetUser) {
        throw new Error("User is no set");
      }
      currentContext.loadCookieToken();
      if (!currentContext.isSetToken) {
        throw new Error("Token is no set");
      }
      return undefined;
    }
    catch (e) {
      return new Error(String(e));
    }
  }



  React.useEffect(() => {

    if (currentContext.JwtIsUp()) {
      setWelcomeState({s: "Подключение и загрузка пользователя ...", run: true, barrier: true});
      genericClient.callHttp(process.env.REACT_APP_JWT_HOST + "/" + currentContext.JwtSet, "GET", [],
        {
          sucess: (data: any): void => {

            try {
              currentContext.JwtSetDown(data);
              let er: Error | undefined = initsystem();
              if (er === undefined) {
                setWelcomeState({s: "", run: false, barrier: false});
                if(openSocket){
                  up(0);
                  setOpenSocket(false);
                }
              }
              else {
                throw er
              };
            }
            catch (e) {
              setWelcomeState({s: String(e), run: false, barrier: true});
            }

          }, error: (data: any): void => {
            setWelcomeState({s: String(data), run: false, barrier: true});
          }
        }, undefined, undefined, undefined, true
      );
    } else {
      __fakedata__();
      let er: Error | undefined = initsystem();
      if (er === undefined) {
        setWelcomeState({s: "", run: false, barrier: false});
        if(openSocket){
          up(0);
          setOpenSocket(false);
        }
      }
      else {
        setWelcomeState({s: String(er), run: false, barrier: true});
      };
    }
   }, []);


  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <BaseStyles />
      <Box sx={{display: 'flex', minHeight: '100dvh'}}>
        {(welcomeState.barrier) ? (
          <>
            <Box sx={{
              display: 'flex', width: '50%',
              alignItems: "center", justifyContent: "center", margin: "auto"
            }}>
              <Typography
                fontWeight="md"
                fontSize="md"
                noWrap
                overflow="auto"
                startDecorator={
                  (welcomeState.run) ? (<CircularProgress size="md" />) : (<ReportIcon />)
                }
              >
                {welcomeState.s}
              </Typography>
            </Box>

          </>
        ) : (
          <>
            <HeadBar />
            <BodyLayout />
            <BaseInfo />
          </>

        )}

      </Box>
    </CssVarsProvider >

  );
}

