import {RestCallback, genericClient} from "./GenericClient";

class DocsRest {

    api:string=genericClient.setUrl( "/storage");
    
    // get all docs - no content
    public getDocs(callback:RestCallback)
    {
        //https://qna.habr.com/q/1021030 
        //['Content-Type', 'application/json'],["Access-Control-Allow-Origin", "*"]
        genericClient.callHttp(this.api, "GET", [], callback);
    }

    // add doc 
    public postDoc (callback:RestCallback, file: File)
    {
        genericClient.callHttpPostFile2(this.api, "POST", "content", callback, file);
    }

    // update doc
    public patchDoc (callback:RestCallback, DocId: string, file: File)
    {           
        genericClient.callHttpPostFile2(this.api + "/" + DocId, "PATCH", "file", callback, file);
    }

    // delete doc
    public deleteDoc(callback:RestCallback, DocID:string, body:string)
    {
        genericClient.callHttpDelete(this.api + "/" + DocID, "DELETE", [], callback, undefined);
    }

    // get doc - with content
    public getDocFile (callback:RestCallback, DocID:string)
    {
        let file: Blob;
        genericClient.callHttpGetFile(this.api + "/" + DocID, "GET", callback);        
        
    }
    
    
}
const docsRest = new DocsRest();

export {docsRest};
