import React, {useCallback} from 'react';
import {Handle, Position, useReactFlow} from '@xyflow/react';
import {Badge, Box, Button, ButtonGroup, Card, CardActions, CardContent, CardCover, Chip, colors, IconButton, Typography} from '@mui/joy';
import {FavoriteBorder, Height} from '@mui/icons-material';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import AddToPhotosRoundedIcon from '@mui/icons-material/AddToPhotosRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import {ustring} from '../../../../types/Alias';
import {DefaultColorPalette} from '@mui/joy/styles/types';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TextFieldsIcon from '@mui/icons-material/TextFields';


const DEFAULT_HANDLE_STYLE = {
  width: 18,
  height: 18,
  bottom: 0,
};




function TypedIcon(prop: {typeid: string, setref: boolean}) {
  return (
    <>
      {prop.typeid === "0" && (<HelpCenterIcon />)}
      {prop.typeid === "1" && (prop.setref) && (<ListRoundedIcon />)}
      {prop.typeid === "1" && (!prop.setref) && (<ArrowForwardRoundedIcon />)}
    </>
  );
}

const sizeNode = {
  width: 320,
  maxHeight: 550
}

type StepNodeContent = {
  id: string,
  normalized: string,
  typeid: string,
  text: string,
  botid: ustring,
  bottxt: ustring,
  docid: ustring,
  doctxt: ustring
  teamid: string,
  teamtxt: string,
  editcallback: (data: StepNodeContent) => void,
  newcallback: (data: StepNodeContent, typeid: string) => void
}


function StepNode(props: {
  id: string,
  data: StepNodeContent,
  isConnectable: boolean,

}) {
  const {deleteElements} = useReactFlow();
  const {id, data, isConnectable} = props;

  const onClick = useCallback(() => {
    deleteElements({nodes: [{id}]});
  }, [id, deleteElements]);

  function getTypeText(): string {
    if (data.typeid === "0") {
      if (data.normalized == "0") {
        return "Начало диалога";
      }
      else {
        return "Вывод сообщения";
      }

    }
    if (data.typeid === "1") {
      if (data.teamid !== undefined)
        return "Перевод в поддержку";
      if (data.botid !== undefined)
        return "Перевод боту"

    }
    return "Вариант выбора";

  }
  function getTypeColor(): string {
    if (data.typeid === "0") {
      if (data.normalized == "0") {
        return "primary.300";
      }
      else {
        return "success.100";
      }
    }
    if (data.typeid === "1") {
      if (data.teamid !== undefined)
        return "danger.100";
      if (data.botid !== undefined)
        return "warning.100"

    }
    return "primary.100";

  }

  function getTypeColorButton(): DefaultColorPalette {
    if (data.typeid === "0") {
      if (data.normalized === "0") {
        return "primary";
      }
      else {
        return "success";
      }
    }
    if (data.typeid === "1") {
      if (data.teamid !== undefined)
        return "danger";
      if (data.botid !== undefined)
        return "warning"

    }
    return "primary";

  }

  return (
    <div>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={data.normalized === "0" ? false : props.isConnectable}
        style={{...DEFAULT_HANDLE_STYLE, backgroundColor: (data.normalized === "0" ? "transparent" : "grey")}}

      />

      <Card
        variant="outlined"
        sx={{
          width: sizeNode.width,
          backgroundColor: getTypeColor(),
          maxHeight: sizeNode.maxHeight,
          borderRadius:(data.normalized === "0" ? 0 : 25)
        }}

      >
        <CardContent sx={{color: "black", overflow: "auto"}}>

            <Typography sx={{color: "inherit"}} startDecorator={<TypedIcon typeid={data.typeid} setref={(data.botid === undefined) && (data.teamid === undefined)} />}
              level="title-lg">{getTypeText()}
            </Typography>
            <Typography level="body-sm" sx={{color: "inherit"}} >
              {data.text}
            </Typography>
            {(data.botid !== undefined) &&
              (
                <Chip color="primary" variant="outlined" size="lg" sx={{mt: 1, p: 1}}
                  startDecorator={<ForwardToInboxIcon />}>
                  {data.bottxt}</Chip>
              )}
            {(data.teamid !== undefined) &&
              (
                <Chip color="primary" variant="outlined" size="lg" sx={{mt: 1, p: 1}}
                  startDecorator={<PhoneForwardedIcon />}>
                  {data.teamtxt}</Chip>
              )}
            {(data.docid !== undefined) &&
              (
                <Chip color="primary" variant="outlined" size="lg" sx={{mt: 1, p: 1}}
                  startDecorator={<AttachFileIcon />}>
                  {data.doctxt}</Chip>
              )}

         
        </CardContent>
        <CardActions buttonFlex="0 1 120px" sx={{borderTop: 0.5}} >
          <ButtonGroup size="md" spacing={"4"}>
            {(data.typeid !== "0") && (
              <IconButton color={getTypeColorButton()} variant="soft" onClick={() => {data.newcallback(data, "0")}} size='lg'><TextFieldsIcon /></IconButton>
            )}
            {((data.botid === undefined) && (data.teamid === undefined)) && (
              <IconButton color={getTypeColorButton()} variant="soft" onClick={() => {data.newcallback(data, "1")}} size='lg'><AddToPhotosRoundedIcon /></IconButton>
            )}
            <IconButton color={getTypeColorButton()} variant="soft" onClick={() => {data.editcallback(data)}} size='lg'><EditNoteRoundedIcon /></IconButton>
            {
            //(data.normalized !== "0") &&
             (
              <IconButton color={getTypeColorButton()} variant="soft" onClick={onClick} size='lg'><DeleteForeverRoundedIcon /></IconButton>
            )}
          </ButtonGroup>
        </CardActions>
      </Card>
 
 
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={props.isConnectable}
        style={{...DEFAULT_HANDLE_STYLE, background: 'grey'}}
      />
    </div>

  );
}

export {StepNode, sizeNode};
export type {StepNodeContent};

