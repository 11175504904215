import { Sheet } from "@mui/joy";
import {observer} from "mobx-react";
import { LeftPanel } from "./LeftPanel";
import { ContentPanel } from "./ContentPanel";

const Layout=observer( () => {

    return (
      <>
        <Sheet 
            sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'minmax(min-content, min(5%, 50px)) 1fr',
              sm: 'minmax(min-content, min(10%, 100px)) 1fr',
              md: 'minmax(min-content, min(10%, 100px)) 1fr',
            }
        }}>
        <Sheet
            sx={{
            position: {xs: 'fixed', sm: 'sticky'},
            zIndex: 100,
            width: '100%',
            top: 'var(--Header-height)',
            borderRight:1 ,
            borderRightColor:'neutral.softHoverBg'
        }}
        >
        <LeftPanel/>
        </Sheet>
        <Sheet
            sx={{
            //height: { xs: 'calc(100dvh - var(--Header-height))', lg: '100dvh' },
            height: "calc(100dvh - var(--Header-height))",
            display: 'flex',
            flexDirection: 'column',
        }}
        >
        <ContentPanel/>
        </Sheet>

        </Sheet>
        </>

    );
    });

export { Layout};
