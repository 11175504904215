import {ButtonGroup, Divider, IconButton, Sheet, Stack, Typography} from "@mui/joy";
import {useTranslation} from "react-i18next";
import DeleteIcon from '@mui/icons-material/PersonOff';
import PersonAdd from '@mui/icons-material/PersonAdd';


function TeamsEditButtonsPanel(props: {
    add?:()=>void,
    delete?:()=>void,
    lableText: string
}) {

    const {t} = useTranslation();

    return (
        <>
            <Sheet
                sx={{
                    borderRadius: 'md',
                    borderStyle: "solid",
                    borderColor: "neutral.softHoverBg",
                    borderWidth: 1,
                    backgroundColor: 'neutral.softBg',
                    p: 1,
                    maxHeight: 'var(--Panel-height))',
                }}
            >

                <Stack direction={"row"}>
                    <Typography level="title-lg" variant='plain' textColor="text.tertiary">{props.lableText}</Typography>
                    <Divider orientation="vertical" sx={{ ml: "15px", mr: "15px" }} ></Divider>
                    <ButtonGroup size="md" spacing={"3"}>
                        <IconButton disabled={!((props.add))} color="primary" variant="soft" onClick={() => { if (props.add) props.add() }}><PersonAdd /></IconButton>
                        <IconButton disabled={!((props.delete))} color="primary" variant="soft" onClick={() => { if (props.delete) props.delete() }}><DeleteIcon /></IconButton>
                    </ButtonGroup>
                </Stack>
            </Sheet>
        </>
    );
}

export default TeamsEditButtonsPanel;
