import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';
import {useTranslation} from 'react-i18next';
import {identity, ustring} from '../types/Alias';
import {Select, Option, IconButton, Box, Chip, Textarea, RadioGroup, Radio, Divider} from '@mui/joy';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

type EditDialogElement = {
  format: "INPUT" | "SELECT" | "DATE" | "NUMBER" | "MULTISELECT" | "FILE" | "TEXTAREA" | "OPTION" | "ECLOSED_OPTSEL",
  title: string;
  key: string,
  currentValue?: string,
  source?: {code: string, value: string, enclosed?: EditDialogElement}[
  ]
  sxLable?: {},
  sxInput?: {},
  search?: (value: string | undefined, pushback: (code: string, value: string) => void) => void,
  search_key?: string,
  currentSearchValue?: string,
  bindindex?: string | undefined,
  bindvalue?: string | undefined,
}


type EditDialogDescriptor = {
  title?: string,
  text?: string,
  apply?: string,
  cancel?: string;
  anchor: identity | undefined;
  visible: boolean;
  elements: EditDialogElement[];
  orientation?: "vertical" | "horizontal",
  $?: any;
  handlResult: (anchor: identity | undefined, isapplied: boolean, result?: Map<string, ustring>, linkedresult?: Map<string, Object>, $?: any) => void
}


function initEditDialogDescriptor(handlResult: (anchor: identity | undefined, isapplied: boolean, result?: Map<string, ustring>, linkedresult?: Map<string, Object>) => void): EditDialogDescriptor {
  return {
    anchor: undefined,
    visible: false,
    elements: [],
    handlResult: handlResult
  }
}

function EditDialog(params: {descriptor: EditDialogDescriptor}) {
  const {descriptor} = params;
  const {t} = useTranslation();

  const [dialogResults, setDialogResuls] = React.useState<Map<string, ustring>>(new Map<string, ustring>())
  const [dialogLinkedResults, setDialogLinkedResults] = React.useState<Map<string, Object>>(new Map<string, Object>())

  const refDialog = React.useRef<HTMLDivElement>(null);




  const handleChangeSelect = (
    key: string,
    newValue: string | null,
  ) => {
    dialogResults.set(key, (newValue === null) ? undefined : newValue);
    setDialogResuls(dialogResults);

  };

  const handleChangeOption = (
    key: string,
    newValue: string,
  ) => {
    dialogResults.set(key, newValue);
    const newMap = new Map<string, ustring>();
    dialogResults.forEach((value, key) => newMap.set(key, value));
    setDialogResuls(newMap);
  };



  const handleChangeMultiSelect = (
    key: string,
    newValue: Array<string> | null,
  ) => {

    //dialogResults.set(key, (newValue === null) ? undefined : newValue.join(","));
    setDialogResuls(dialogResults);;

  };
  const handleChangeSelectRecreate = (
    key: string,
    newValue: string | null,
  ) => {
    dialogResults.set(key, (newValue === null) ? undefined : newValue);
    const newMap = new Map<string, ustring>();
    dialogResults.forEach((value, key) => newMap.set(key, value));
    setDialogResuls(newMap);

  };

  // Clear result before result (MAIN ****)
  function cloneAndClear(apply: boolean): Map<string, ustring> {
    if (!apply) {
      setDialogResuls(new Map<string, ustring>());
      return dialogResults;
    } else {
      const newMap = new Map<string, ustring>();
      dialogResults.forEach((value, key) => newMap.set(key, value));
      setDialogResuls(new Map<string, ustring>());
      return newMap;

    }
  }

  function cloneAndClearLinked(apply: boolean): Map<string, Object> {
    if (!apply) {
      setDialogLinkedResults(new Map<string, Object>());
      return dialogLinkedResults;
    } else {
      const newMap = new Map<string, Object>();
      dialogLinkedResults.forEach((value, key) => newMap.set(key, value));
      setDialogLinkedResults(new Map<string, Object>());
      return newMap;

    }
  }

  function eValuateBindIndex(bindindex?: string | undefined, bindvalue?: string | undefined) {
    if (bindindex === undefined) {return true;}
    else {
      const value = dialogResults.get(bindindex);
      if (value === undefined) {
        return false;
      } else {
        return (value === bindvalue);
      }
    }

  }

  React.useEffect(() => {

    if (descriptor.visible) {

      // Clear result before show and fill preset - Do not delete (SECOND ****)   
      const newMap = new Map<string, ustring>();
      //Clear and Fill
      descriptor.elements.forEach(
        (value: EditDialogElement) => {
          if (value.currentValue) {
            newMap.set(value.key, (value.currentValue === null) ? undefined : value.currentValue);
          }
          if (value.currentSearchValue && value.search_key) {
            newMap.set(value.search_key, (value.currentSearchValue === null) ? undefined : value.currentSearchValue);
          }
        }
      );
      setDialogResuls(newMap);
      setDialogLinkedResults(new Map<string, Object>())

    }
  }, [descriptor.visible]);


  const handleClickAttach = (id: string) => {
    if (refDialog.current) {
      (refDialog.current?.ownerDocument.getElementById(id) as HTMLInputElement)?.click();
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if (e.target.files) {
      handleChangeSelectRecreate(key, e.target.files[0].name);
      dialogLinkedResults.set(key, e.target.files[0]);
      setDialogLinkedResults(dialogLinkedResults);
      e.target.value = "";
    }
  };

  return (
    <React.Fragment>
      <Modal open={descriptor.visible} >
        <ModalDialog>


          {descriptor.title &&
            (<DialogTitle sx={{borderBottom: 0.5, borderBottomColor: "InactiveBorder", paddingBottom: 1}}>{descriptor.title}</DialogTitle>)
          }
          {descriptor.text &&
            (<DialogContent sx={{fontSize: "small"}}>{descriptor.text}</DialogContent>)
          }

          <Stack spacing={2}>

            {descriptor.elements.map(
              (element: EditDialogElement) => {



                return (
                  <FormControl orientation={(descriptor.orientation) ? descriptor.orientation : "vertical"}
                    ref={refDialog}>
                    {element.format === "INPUT" &&
                      (
                        <>
                          <FormLabel sx={{...element.sxLable}}>{element.title}</FormLabel>
                          {(element.search)
                            ? (
                              <Input
                                endDecorator={<IconButton
                                  onClick={() => {
                                    if (element.search) {
                                      element.search(dialogResults.get(element.key), (codeS: string, valueS: string) => {
                                        handleChangeSelectRecreate(element.key, valueS);
                                        if (element.search_key)
                                          handleChangeSelectRecreate(element.search_key, codeS);
                                      });

                                    }
                                  }}

                                ><SearchRoundedIcon /></IconButton>}
                                sx={{
                                  ...element.sxInput
                                  ,
                                  ...((
                                    (element.search_key)
                                    && (dialogResults.get(element.key))
                                    && (!dialogResults.get(element.search_key))
                                  ) ? {backgroundColor: "danger.200"} : {})
                                }} key={element.key} defaultValue={element.currentValue}
                                onChange={(event) => {
                                  handleChangeSelectRecreate(element.key, event.target.value)
                                  if (element.search_key)
                                    handleChangeSelectRecreate(element.search_key, null);

                                }

                                }
                                value={dialogResults.get(element.key)}
                                size='sm'
                              />)
                            : (
                              <Input
                                sx={{...element.sxInput}} key={element.key} defaultValue={element.currentValue}
                                onChange={(event) => handleChangeSelect(element.key, event.target.value)}
                                size='sm' />)
                          }


                        </>
                      )}
                    {element.format === "TEXTAREA" &&
                      (
                        <>
                          <FormLabel sx={{...element.sxLable}}>{element.title}</FormLabel>
                          <Textarea
                            sx={{...element.sxInput}} key={element.key} defaultValue={element.currentValue}
                            onChange={(event) => handleChangeSelect(element.key, event.target.value)}
                            minRows={4}
                            maxRows={4}
                            size='sm'
                          />

                        </>
                      )}
                    {element.format === "DATE" &&
                      (
                        <>
                          <FormLabel sx={{...element.sxLable}}>{element.title}</FormLabel>
                          <Input sx={{...element.sxInput}} size='sm'
                            type="date" key={element.key}
                            // slotProps={{
                            //   input: {
                            //     min: 'DD/MM/YYYY',
                            //     max: 'DD/MM/YYYY',
                            //   },
                            // }}
                            defaultValue={element.currentValue} onChange={(event) => handleChangeSelect(element.key, event.target.value)} />
                        </>
                      )}
                    {element.format === "NUMBER" &&
                      (
                        <>
                          <FormLabel sx={{...element.sxLable}}>{element.title}</FormLabel>
                          <Input sx={{...element.sxInput}} size='sm'
                            type="number" key={element.key} defaultValue={element.currentValue} onChange={(event) => handleChangeSelect(element.key, event.target.value)} />
                        </>
                      )}
                    {element.format === "OPTION" &&
                      (
                        <>
                          <FormControl>
                            <FormLabel sx={{...element.sxLable}}>{element.title}</FormLabel>
                            <RadioGroup defaultValue={element.currentValue} key={element.key} name={element.key}
                              onChange={((event: React.ChangeEvent<HTMLInputElement>) => handleChangeOption(element.key, event.target.value))}
                            >
                              {element.source?.map(
                                (item: {code: string, value: string}) => {
                                  return (
                                    <Radio value={item.code} label={item.value} variant="outlined" />
                                  );
                                }
                              )
                              }
                            </RadioGroup>
                          </FormControl>

                        </>
                      )}
                    {(element.format === "SELECT") &&
                      (
                        <>
                          <FormLabel sx={{...element.sxLable}}>{element.title}</FormLabel>
                          <Select sx={{...element.sxInput}}
                            defaultValue={element.currentValue} key={element.key} onChange={(event: React.SyntheticEvent | null,
                              newValue: string | null) => handleChangeSelect(element.key, newValue)}
                            size='sm'>
                            {element.source?.map(
                              (item: {code: string, value: string}) => {
                                return (
                                  <Option value={item.code}>{item.value}</Option>
                                );
                              }
                            )
                            }
                          </Select>
                        </>
                      )}

                    {element.format === "ECLOSED_OPTSEL" &&
                      (
                        <>
                          <FormControl>
                            <FormLabel sx={{...element.sxLable}}>{element.title}</FormLabel>
                            <RadioGroup defaultValue={element.currentValue} key={element.key} name={element.key}
                              onChange={((event: React.ChangeEvent<HTMLInputElement>) => handleChangeOption(element.key, event.target.value))}
                            >
                              {element.source?.map(
                                (item: {code: string, value: string, enclosed?: EditDialogElement}) => {
                                  return (
                                    <>
                                      <Radio value={item.code} label={item.value} variant="outlined" sx={{mb: 1}} />

                                      {(item.enclosed) && (
                                        <>
                                          <Select sx={{...item.enclosed.sxInput, mb: 1}}
                                            disabled={!(eValuateBindIndex(item.enclosed.bindindex, item.enclosed.bindvalue))}
                                            defaultValue={item.enclosed.currentValue} key={item.enclosed.key}
                                            onChange={(event: React.SyntheticEvent | null,
                                              newValue_en: string | null) =>
                                              (item.enclosed) ? handleChangeSelect(item.enclosed.key, newValue_en) : {}
                                            }
                                            size='sm'>
                                            {item.enclosed.source?.map(
                                              (item_en: {code: string, value: string}) => {
                                                return (
                                                  <Option value={item_en.code}>{item_en.value}</Option>
                                                );
                                              }
                                            )
                                            }
                                          </Select>
                                        </>
                                      )
                                      }

                                    </>
                                  );
                                }
                              )
                              }
                            </RadioGroup>
                          </FormControl>

                        </>
                      )}

                    {element.format === "MULTISELECT" &&
                      (
                        <>
                          <FormLabel sx={{...element.sxLable}}>{element.title}</FormLabel>
                          <Select sx={{...element.sxInput}}
                            size='sm'
                            defaultValue={(element.currentValue) ? element.currentValue.split(",") : []}
                            multiple
                            key={element.key}
                            onChange={(event: React.SyntheticEvent | null,
                              newValue: Array<string> | null) => handleChangeMultiSelect(element.key, newValue)}
                            renderValue={(selected) => (
                              <Box sx={{display: 'flex', gap: '0.25rem'}}>
                                {selected.map((selectedOption) => (
                                  <Chip variant="soft" color="primary">
                                    {selectedOption.label}
                                  </Chip>
                                ))}
                              </Box>
                            )}
                          >
                            {element.source?.map(
                              (item: {code: string, value: string}) => {
                                return (
                                  <Option value={item.code}>{item.value}</Option>
                                );
                              }
                            )
                            }
                          </Select>
                        </>
                      )}
                    {element.format === "FILE" &&
                      (
                        <>
                          <input id={"inputLoadFile_" + element.key} type="file" style={{display: 'none'}}
                            onChange={((e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e, element.key))} />
                          <FormLabel sx={{...element.sxLable}}>{element.title}</FormLabel>
                          <Input sx={{...element.sxInput}}
                            size='sm'
                            endDecorator={<IconButton
                              onClick={() => {
                                handleClickAttach("inputLoadFile_" + element.key);
                              }}
                            ><AttachFileIcon /></IconButton>}
                            readOnly
                            key={element.key}
                            value={dialogResults.get(element.key)}
                            onChange={(event) => handleChangeSelect(element.key, event.target.value)} />
                        </>
                      )}

                  </FormControl>
                );
              }
            )
            }
            <Stack spacing={2} direction={"row-reverse"}>
              <Button onClick={() => descriptor.handlResult(descriptor.anchor, false, cloneAndClear(false), cloneAndClearLinked(false), descriptor?.$)}>{(descriptor.cancel) ? descriptor.cancel : t("Button_Cancel")}</Button>
              <Button onClick={() => descriptor.handlResult(descriptor.anchor, true, cloneAndClear(true), cloneAndClearLinked(true), descriptor?.$)}>{(descriptor.apply) ? descriptor.apply : t("Button_Apply")}</Button>
            </Stack>
          </Stack>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}

export {EditDialog, initEditDialogDescriptor};
export type {EditDialogDescriptor, EditDialogElement};
