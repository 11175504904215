import * as React from 'react';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Snackbar, {SnackbarProps} from '@mui/joy/Snackbar';
import currentInfoStore from '../store/CurrentInfoStore';
import {observer} from 'mobx-react-lite';
import {Box, IconButton, Typography} from '@mui/joy';
import {useTranslation} from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';


const BaseInfo = observer(() => {
  const {t} = useTranslation();
  return (
    <>

      <Snackbar
        //autoHideDuration={3000}
        open={currentInfoStore.isShow}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        variant="soft"
        color={currentInfoStore.Color}
        sx={{
          zIndex: 9996, top: "var(--Header-height)"
        }}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            currentInfoStore.hide();
          }
          currentInfoStore.hide();
        }}
        startDecorator={
          (currentInfoStore.Flag === "ERROR") ? (<ErrorIcon />)
            : ((currentInfoStore.Flag === "SUCESS") ? (<ThumbUpAltIcon />) : (<InfoIcon />))
        }
        endDecorator={
          <IconButton size='sm' onClick={() => currentInfoStore.hide()}>
            <CloseIcon />
          </IconButton>
        }
      >
        <Box 
        sx={{maxHeight:300}}
        overflow="auto" >
        {currentInfoStore.Text}
        </Box>
        
      </Snackbar>
    </>

  );
});
export default BaseInfo;