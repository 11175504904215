import {KeyboardArrowRight, Person} from "@mui/icons-material";
import MediationIcon from '@mui/icons-material/Mediation';
import {List, ListItem, ListItemButton, ListItemContent, ListItemDecorator, Sheet} from "@mui/joy";
import {observer} from "mobx-react-lite";

import UserType from "../../../types/UserType";
import {useTranslation} from "react-i18next";
import React from "react";
import currentInfoStore from "../../../store/CurrentInfoStore";
import {botUsersRest as usersRest} from "../../../net/rest/BotUsersRest";
import {EditDialog, EditDialogDescriptor, initEditDialogDescriptor} from "../../../components/EditDialog";
import {identity, ustring} from "../../../types/Alias";
import {CaptionPanel} from "../../../components/CaptionPanel";
import {EditButtonsPanel} from "../../../components/EditButtonsPanel";
import {ListDecorator} from "../../../components/ListDecorator";
import usersStore from "../../../store/UsersStore";
import TeamType from "../../../types/TeamType";
import {teamsRest} from "../../../net/rest/TeamsRest";
import teamStore from "../../../store/TeamStore";
import docsStore from "../../../store/DocsStore";
import DocsType from "../../../types/DocsType";
import {docsRest} from "../../../net/rest/DocsRest";
import {TypesOfUsers} from "../../../store/Enumerations";

const LeftPanel = observer(() => {

    const {t} = useTranslation();

    const [refresh, setRefresh] = React.useState<boolean>(false);
    const [showStatusLeft, setShowStatusLeft] = React.useState<boolean>(true);
    //Dialogs state
    const [dlgDescriptor, setDlgDescriptor] = React.useState<EditDialogDescriptor>(initEditDialogDescriptor(handlResultDialog));

    //Dialogs close
    function closeDialog() {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            return {anchor: undefined, visible: false, elements: [], handlResult: reset.handlResult};
        });
    }

    //Dialogs show
    function showDialog() {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            reset.visible = true;
            return {...reset};
        });
    }

    function handlResultDialog(anchor: identity | undefined, isapplied: boolean, result?: Map<string, ustring>) {
        closeDialog();
        // TODO
        if (isapplied) {

            switch (anchor) {
                case "ADD":
                    if (!result?.get("BotName")) {
                        currentInfoStore.setInfo(t('inputBotName'), "ERROR");
                        break;
                    }
                    let newUserAudit = usersStore.getUserByName(result?.get("BotName"));
                    if (newUserAudit !== undefined) {
                        currentInfoStore.setInfo(result?.get("BotName") + " - " + t("exists"), "ERROR");
                        break;
                    }
                    setShowStatusLeft(true);
                    const json = {
                        nameds: result?.get("BotName") || "NoName",
                        botfl: "true",
                        activefl: "1",
                        usertypeid: TypesOfUsers.Bots,
                        blockedfl: "0",
                        profile: "{}",
                        teams: [...(result?.get("teamid")) ? [{teamid: result?.get("teamid")}] : []]
                    }


                    usersRest.postUsers(

                        {
                            sucess: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                                usersStore.addUser(data.content[0]
                                )

                            },
                            error: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                                currentInfoStore.setInfo("Bots " + data, "ERROR");
                            },
                            envoy: {id: (new Date()).getTime() + "", ds_name: result?.get("BotName") || "NoName"}

                        },
                        JSON.stringify(json)
                    );




                    break;
                case "UPD":
                    if (!result?.get("BotName")) {

                        break;
                    }
                    let changeUserAudit = usersStore.getUserByName(result?.get("BotName"));
                    if (changeUserAudit !== undefined) {
                        currentInfoStore.setInfo(result?.get("BotName") + " - " + t("exists"), "ERROR");
                        break;
                    }
                    const selecledUser = usersStore.getUserByID(usersStore.SelectedIdUser);
                    if (selecledUser) {

                        setShowStatusLeft(true);
                        const json = {
                            userid: selecledUser.userid,
                            nameds: result?.get("BotName") || "NoName",
                            botfl: selecledUser.botfl,
                            activefl: selecledUser.activefl,
                            blockedfl: selecledUser.blockedfl,
                            profile: selecledUser.profile,
                        }


                        usersRest.ChangeUsers(

                            {
                                sucess: (data: any, envoy?: any) => {
                                    setShowStatusLeft(false);
                                    if (data.message !== undefined) {
                                        currentInfoStore.setInfo("Bots " + data.message, "ERROR");
                                    }
                                    else {
                                        selecledUser.nameds = result?.get("BotName") || "NoName";
                                    }

                                },
                                error: (data: any, envoy?: any) => {
                                    setShowStatusLeft(false);
                                    currentInfoStore.setInfo("Bots " + data, "ERROR");
                                },

                            },
                            JSON.stringify(json)
                        );
                    }

                    break;
                case "DEL":
                    const jsonDel = usersStore.getUserByID(usersStore.SelectedIdUser);
                    usersRest.DeleteUsers(

                        {
                            sucess: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                                const selecledUserDelete = usersStore.getUserByID(usersStore.SelectedIdUser);
                                if (selecledUserDelete) {
                                    usersStore.deleteUser(selecledUserDelete);
                                }

                            },
                            error: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                                currentInfoStore.setInfo("Bots " + data, "ERROR");
                            },
                        },
                        usersStore.SelectedIdUser, JSON.stringify(jsonDel)
                    );
                    break;
                default:
                    break;
            }
        }

    }


    //Модальное окно добавления пользователя
    function addRecord() {
        dlgDescriptor.title = t("Add_BotTitle");
        dlgDescriptor.apply = t("Button_ADD");
        dlgDescriptor.cancel = t("Button_Cancel");
        dlgDescriptor.elements = [
            {format: "INPUT", key: "BotName", title: t("Elements_Call_Title")},
            {
                format: "SELECT", key: "teamid", title: t('defaultteam'), currentValue: "",
                source: [
                    {code: "", value: ""},
                    ...teamStore.getTeams().map((team: TeamType) => {
                        return {code: team.teamid, value: team.teamds}
                    }
                    )]
            },
        ];

        dlgDescriptor.anchor = "ADD";
        showDialog();
    }

    //Updating
    function updateRecord() {
        const selecledUser = usersStore.getUserByID(usersStore.SelectedIdUser);
        if (selecledUser) {
            dlgDescriptor.title = t("Edit_BotTitle");
            dlgDescriptor.apply = t("Button_change");
            dlgDescriptor.cancel = t("Button_Cancel");
            dlgDescriptor.elements = [{format: "INPUT", key: "BotName", title: t("Elements_Call_Title"), currentValue: selecledUser.nameds}];
            dlgDescriptor.anchor = "UPD";
            showDialog();
        }
    }

    //Deleting
    function deleteRecord() {
        const selecledUser = usersStore.getUserByID(usersStore.SelectedIdUser);
        if (selecledUser) {
            dlgDescriptor.title = t("Delete_Title");
            dlgDescriptor.cancel = t("Button_Cancel");
            dlgDescriptor.apply = t("Button_Delete");
            dlgDescriptor.text = `${t("Delete_Text")} ${selecledUser.nameds} ?`;
            dlgDescriptor.elements = [];
            dlgDescriptor.anchor = "DEL";
            showDialog();
        }
    }



    function updateUsersAsBots() {
        setShowStatusLeft(true);
        usersStore
        .clearSelection()
        .deleteUsersBy((value: UserType) => value.usertypeid===TypesOfUsers.Bots);
        
        usersRest.getUsers({
            sucess: (data: any) => {
                data.content.forEach((value: UserType) => {
                    usersStore.addUser(value);
                })
                setShowStatusLeft(false);
            },
            error: (data: any) => {
                currentInfoStore.setInfo("Bots " + data, "ERROR");
                setShowStatusLeft(false);
            }
        }, [["usertypeid", TypesOfUsers.Bots], ["size", "999999"]]);
    }





    function updateTeams_silent() {
        teamsRest.getTeams({
            sucess: (data: any): void => {

                data.content.forEach((value: TeamType) => {
                    teamStore.addTeam(value);
                })
            }, error: (data: any): void => {
                setShowStatusLeft(false);
                currentInfoStore.setInfo("Teams refill" + data, "ERROR");
            }
        }
        );
    }



    function updateDocs_silent() {

        docsRest.getDocs({
            sucess: (data: any): void => {

                data.forEach((value: DocsType) => {
                    docsStore.addDoc(value);
                })
            }, error: (data: any): void => {
                setShowStatusLeft(false);
                currentInfoStore.setInfo("Docs refill" + data, "ERROR");
            }
        }


        );
    }



    React.useEffect(() => {
        updateUsersAsBots();
        updateTeams_silent();
        updateDocs_silent();
        return () => {

        };
    }, [refresh]);

    return (
        <>
            <Sheet
                sx={{
                    width: 300,
                    height: 'calc(100dvh - var(--Header-height))',
                    display: 'flex',
                    flexDirection: 'column',

                }}
            >

                <CaptionPanel caption={t("title_bots")}
                    status={() => showStatusLeft}
                    refresh={() => setRefresh(!refresh)} />


                <ListDecorator>
                    <List size="sm">

                        {usersStore.Display((value) => value.usertypeid === TypesOfUsers.Bots)
                            .map((value: UserType) =>
                            (
                                <ListItem
                                    variant={((usersStore.SelectedIdUser === value.userid)) ? "outlined" : "plain"}
                                    sx={{backgroundColor: (usersStore.SelectedIdUser === value.userid) ? "primary.softHoverBg" : "inherit"}}
                                    key={value.userid} >
                                    <ListItemButton variant="plain"
                                        onClick={() => {
                                            usersStore.SelectedName = value.nameds;
                                            usersStore.SelectedIdUser = value.userid;
                                        }}>
                                        <ListItemDecorator><MediationIcon /></ListItemDecorator>
                                        {/* <ListItemContent>{value.ds_name} ({value.teams.length})</ListItemContent> */}
                                        <ListItemContent>{value.nameds}</ListItemContent>
                                        {(usersStore.SelectedIdUser === value.userid) && (<KeyboardArrowRight />)}
                                    </ListItemButton>
                                </ListItem>
                            ))}
                    </List>
                </ListDecorator>
                <EditButtonsPanel add={addRecord} update={updateRecord} delete={deleteRecord} />
            </Sheet>
            <EditDialog descriptor={dlgDescriptor} />
        </>

    );
});


export {LeftPanel};