import {makeAutoObservable} from "mobx";


class SockStateStore {
    private $state: number=-1;
    private $attempt: number=0;
    private $count :number=0;

    constructor() {
        makeAutoObservable(this);
    }
    setSockStateInfo(state: number, attempt: number) {
        this.$state = state;
        this.$attempt=attempt;
    }

    
    public get state(): number
    {
        return this.$state
    }
    
    public get attempt(): number
    {
        return this.$attempt
    }

    public set state(value:number)
    {
        this.$state=value;
    }
    
    public set attempt(value:number)
    {
        this.$attempt=value;
    }
    
    public get count(): number
    {
        return this.$count
    }

    public set count(value:number)
    {
        this.$count=(value<=0)?0:value;
    }
    

}
export {}
export const sockStateStore = new SockStateStore();