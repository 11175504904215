import {makeAutoObservable} from "mobx";
import {DialogTypeC} from "../types/DialogType";
import {identity} from "../types/Alias";

type NewMsgType = {dialogid:identity,NewMsg:boolean};
class DialogsStore {
    
    
    private $dialogs: DialogTypeC[] = [];
    private $selected: DialogTypeC | undefined = undefined;
    private $dialogNewMSG: NewMsgType[] = [];


    public constructor() {
        makeAutoObservable(this);
    }


    public clear() {
        this.$selected = undefined
        this.$dialogs = [];
    }


    /** add Dialog 
    */

    public addDialog(_value: DialogTypeC) {

        //SYNC TYPES !!!
        const value: DialogTypeC =
        {
            dialogid: String(_value.dialogid),
            user: {
                nameds: String(_value.user.nameds),
                userid: String(_value.user.userid)
            },
            team: {
                teamds: String(_value.team.teamds),
                teamid: String(_value.team.teamid)
            },
            assignee: (_value.assignee) ? {
                nameds: String(_value.assignee.nameds),
                userid: String(_value.assignee.userid)
            } : undefined,
            priority: {
                priorityds: String(_value.priority.priorityds),
                priorityid: String(_value.priority.priorityid)
            },
            dialogtags: _value.dialogtags,
            lastupdate: String(_value.lastupdate),
            closedreason: {
                closedreasonds: String(_value.closedreason.closedreasonds),
                closedreasonid: String(_value.closedreason.closedreasonid)
            },
            status: {

                statusds: String(_value.status.statusds),
                statusid: String(_value.status.statusid)

            },

        }

        let i: number = this.$dialogs.findIndex((valueF: DialogTypeC) => valueF.dialogid === value.dialogid);
        if (i >= 0) {
            this.$dialogs[i] = {...value};
        } else {
            this.$dialogs.push(value);
        }

    }

    public changeNewMSG(dialogID:string,value:boolean){
        let i: number = this.$dialogNewMSG.findIndex((valueF: NewMsgType ) => valueF.dialogid == dialogID);
        if (i >= 0) {
            this.$dialogNewMSG[i].NewMsg=value;
        } else {
            const Obj: NewMsgType = {
            dialogid: dialogID,
            NewMsg: value}
            this.$dialogNewMSG.push(Obj);
        }
    }

    public NewMessage(dialogID:string):boolean{
        let i: number = this.$dialogNewMSG.findIndex((valueF: NewMsgType ) => valueF.dialogid == dialogID);
        if (i >= 0) {
            return this.$dialogNewMSG[i].NewMsg;
        } else {
            return false;
        }
    }

    //select
    public get Selected(): DialogTypeC | undefined {
        return this.$selected;
    }

    //display
    public Display(predicate?: (value: DialogTypeC) => boolean): DialogTypeC[] {
        if (!predicate) {
            return this.$dialogs;
        }
        else {
            return this.$dialogs.filter(predicate);
        }
    }

    public select(id: identity): void {
        this.$selected = this.$dialogs.find((value: DialogTypeC) => {
            return value.dialogid === id;
        }
        )
    }


}

const dialogsStore = new DialogsStore();
export default dialogsStore;