import {makeAutoObservable} from "mobx";
import UserType from "../types/UserType";
import Cookies from 'universal-cookie'
import {identity} from "../types/Alias";



class CurrentContext {
    private $user: UserType | undefined = undefined;
    private $localuser: {
        i: identity,
        g?: string | undefined,
        n?: string | undefined,
        e?: string | undefined
    } | undefined = undefined;
    private $token: identity | undefined = "mptoken";//undefined;
    private $jwtset: identity | undefined = undefined;


    public constructor() {
        makeAutoObservable(this);
    }

    public get User(): UserType | undefined {
        return this.$user
    }
    public __set__test__(t?: any, u?: any) {
        if (u) {
            this.$localuser = {...u};
            this.saveCookieUser();
        }
        if (t) this.$token = t;
    }
    public get UserID(): identity {
        return (this.$localuser) ? this.$localuser.i : "-1";
    }

    public set User(value: UserType | undefined) {
        if (value) {
            this.$user = undefined;
            this.$user = {...value};
        }
    }


    public get LocalUserName(): string {
        return (this.$localuser) ? (this.$localuser.n) ? this.$localuser.n : "" : "";
    }

    public get isSetUser(): boolean {
        return ((this.$localuser !== undefined) && (this.$localuser.i.length > 2));
    }

    public get isSetToken(): boolean {
        return ((this.$token !== undefined) && (this.$token.length > 2));
    }

    public get Token(): string | undefined {
        return this.$token
    }
    public set Token(value: string | undefined) {
        this.$token = value;

    }


    public get JwtSet(): string | undefined {
        return this.$jwtset
    }
    public set JwtSet(value: string | undefined) {
        this.$jwtset = value;

    }


    public JwtSetDown(dataJson: any) {
        if ((dataJson.id_user) && (String(dataJson.id_user).length > 2)) {
            this.$localuser = {
                i: String(dataJson.id_user),
                g: String(dataJson.ds_gpid),
                n: String(dataJson.description),
                e: String(dataJson.email)
            }
            this.$jwtset = undefined;
            this.saveCookieUser();
            (new Cookies()).remove("jwt");
        }
        else {
            throw new Error("Invalid iSales UserID");
        }

    }

    public loadCookieToken() {
        let token: string = (new Cookies()).get('xt');
        this.$token = (token === undefined) || (token.trim().length < 1) ? this.$token : token;
    }

    private saveCookieToken(token: string) {
        (new Cookies()).set('xt', token, {path: "/", maxAge: 259200 * 100});

    }

    public refreshToken(token: identity | undefined | null) {
        if (token) {
            this.$token = token;
            this.saveCookieToken(token);
        }

    }

    public loadCookieUser(): Error | undefined {
        let localuser = (new Cookies()).get('user');
        if (localuser) {
            try {
                this.$localuser = {...localuser};
            } catch (e) {
                return new Error(e + " ");
            }
        }
        else
            this.$localuser = undefined;
        return undefined;
    }

    private saveCookieUser() {
        if (this.$localuser) {
            (new Cookies()).set('user', JSON.stringify(this.$localuser), {path: "/", maxAge: 259200 * 100});
            (new Cookies()).set('us', this.$localuser.i, {path: "/", maxAge: 259200 * 100});
        }
        else {
            (new Cookies()).remove("user");
            (new Cookies()).remove("us");
        }

    }

    public JwtIsUp(): boolean {
        let jwtset: string = (new Cookies()).get('jwt');
        this.$jwtset = ((jwtset === undefined) || (jwtset.trim().length < 1)) ? undefined : jwtset;
        return (this.$jwtset !== undefined);
    }




}
const currentContext = new CurrentContext();
// currentContext.loadCookieToken();
// currentContext.loadCookieUser();
export default currentContext;



