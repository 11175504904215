import {makeAutoObservable} from "mobx";
import UserType from "../types/UserType";
import TeamType from "../types/TeamType";
import {identity, identity_null, ustring} from "../types/Alias";





/**
 * класс UsersStore отвечает за хранение и предоставление данных по списку пользователей
 * и операций над этим список и над конкретным пользоваталем
 */
class UsersStore {

    private $users: UserType[] = [];
    private $select_Name: String = "";
    private $select_Team: string[] = [];
    private $selected_Users: string[] = [];
    private $selected_iduser: identity = identity_null;

    public constructor() {
        makeAutoObservable(this);
    }

    /**Добавляет пользователя в Store */
    public addUser(_value: UserType) {

        //SYNC TYPES !!!
        const value: UserType =
        {
            userid: String(_value.userid),
            nameds: String(_value.nameds),
            botfl: Boolean(_value.botfl),
            activefl: Boolean(_value.activefl),
            usertypeid: String(_value.usertypeid),
            profile: _value?.profile,
            blockedfl: Boolean(_value.blockedfl),
            teams: [
                ..._value.teams.map((elem) => {
                    return {
                        teamid: String(elem.teamid),
                        teamds: String(elem.teamds),
                        activefl: Boolean(elem.activefl)
                    }
                })
            ]

        }

        let i: number = this.$users.findIndex((valueF: UserType) => valueF.userid === value.userid);
        //Только актив
        if (value.activefl) {
            if (i >= 0) {
                this.$users[i] = {...value};
            } else {
                //this.$users.push(value);
                this.$users.push(value);
            }
        }

    }

    /** возвращает список пользователей */
    public getUsers(): UserType[] {
        return this.$users;
    }


    /** возвращает список пользователей по списку id
     * используется фильтрация массива там где у элемента id в списке ids переданной в сигнатуре
    */
    public getUsersByIDs(ids_list: identity[]): UserType[] {
        return this.$users.filter((value: UserType) => {
            return ids_list.indexOf(value.userid) >= 0;
        });
    }

    public getUserByID(id: identity): UserType | undefined {
        const user = this.getUsersByIDs([id]);
        return user.length === 0 ? undefined : user[0];
    }

    /** возвращает список пользователей по списку names
     * используется фильтрация массива 
    */
    public getUsersByNames(names_list: string[]): UserType[] {
        return this.$users.filter((value: UserType) => {
            return names_list.indexOf(value.nameds) >= 0;
        });

    }

    public getUserByName(name: string | any): UserType | undefined {
        const user = this.getUsersByNames([name]);
        return user.length === 0 ? undefined : user[0];
    }


    /** возвращает список пользователей входящих в команды, используется фильтрация 
     * с насильственным прерывание цикла foreach если нашлось хоть одно совпадение 
    */
    public getUsersByТeamsIDs(id_teams_list: string[]): UserType[] {
        return this.$users.filter((value) => {
            let found: boolean = false;
            if ((value.teams) && (value.teams.length > 0)) {

                let ForceBreakForEach = {};
                try {
                    value.teams.forEach((team) => {
                        if (id_teams_list.indexOf(team.teamid) >= 0)
                            throw ForceBreakForEach;
                    });
                }
                catch (e) {
                    if (e === ForceBreakForEach) found = true;
                }
            }
            return found;
        }).sort((a, b) => +a.userid - +b.userid);
    }

    public getUsersByТeams(teams_list: TeamType[]): UserType[] {
        let id_teams_list: identity[] = teams_list.map(value => value.teamid);
        return this.getUsersByТeamsIDs(id_teams_list);
    }


    /** чистит список 
    */
    public clearUsers():UsersStore {
        this.clearSelection();
        this.$users = [];
        return this;

    }


    public clearSelection():UsersStore {
        this.$select_Name = "";
        this.$select_Team = [];
        this.$selected_Users = [];
        this.$selected_iduser = identity_null;
        return this;
    }



    /** удаляет пользователей 
    */
    public deleteUsers(users: UserType[]):UsersStore {
        users.forEach((value: UserType) => {
            let i: number = this.$users.findIndex((valueF: UserType) => valueF.userid === value.userid);
            if (i >= 0) {
                this.$users.splice(i, 1);
            }
        });
        return this;
    }

    public deleteUsersBy(predicate: (value: UserType) => boolean):UsersStore {
        this.$users.forEach((value: UserType,index:number,thisArray:UserType[]) => {
            if (predicate(value)) {
                thisArray.splice(index, 1);
            }
        });
        return this;
    }
    

    public deleteUser(user: UserType):UsersStore {
        let i: number = this.$users.findIndex((valueF: UserType) => valueF.userid === user.userid);
        if (i >= 0) {
            this.$users.splice(i, 1);
        }
        return this;
    };

    /** пересобирает входящий массив сигнатуры из массива this.$users
     * в том случае если массив в сигнатуре взят откуда еще не из this.$users
    */
    private doChangeSource(users: UserType[]): UserType[] {
        let id_user_list: identity[] = users.map(value => value.userid);
        return this.$users.filter((value: UserType) => {
            return id_user_list.indexOf(value.userid) >= 0;
        });
    }

   

    //display
    public Display(predicate?: (value: UserType) => boolean): UserType[] {
        if (!predicate) {
            return this.$users.slice().sort((a, b) => +a.userid - +b.userid);
        }
        else {
            return this.$users.filter(predicate).sort((a, b) => +a.userid - +b.userid);
        }
    }

    //ids
    public get SelectedName() {return this.$select_Name}
    public set SelectedName(value: String) {this.$select_Name = value}

    //ids
    public get SelectedTeams() {return this.$select_Team}
    public set SelectedTeam(value: string[]) {this.$select_Team = value}

    // getter
    public get SelectedUsers() {return this.$selected_Users}
    //setter
    public set SelectedUsers(value: string[]) {this.$selected_Users = value}

    public get SelectedIdUser(): identity {return this.$selected_iduser;}
    public get SelectedNameUser(): string {

        const u = this.$users.find((value) => {return value.userid === this.$selected_iduser});
        return (u === undefined) ? "" : u.nameds;

    }

    public set SelectedIdUser(value: identity) {this.$selected_iduser = value;}
}


const usersStore = new UsersStore();
export default usersStore;








