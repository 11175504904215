import currentContext from "./CurrentContext";
import lo from "./Logging";


//currentUser.UserID="614076";
//

export default function __fakedata__()
{
    currentContext.__set__test__("mptoken",
        {i:"614076",n:"Roman"}
    );
    lo.RestEnable=true;
    lo.WsEnable=true;
};


