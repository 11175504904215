import {makeAutoObservable} from "mobx";
import {datetime} from "../types/Alias";



class Lo {
    private $rest: {df:datetime,headers: string, url: string}[] = [];
    private $ws: {df:datetime,dir: "IN" | "OUT", url: string}[] = [];
    private restEnable: boolean = false;
    private wsEnable: boolean = false;

    public constructor() {
        makeAutoObservable(this);
    }

    public addUrl(headers: [string, string][], url: string) {
        let h = "";
        for (let i = 0; i < headers.length; i++) {
            h = h  + headers[i][0] + ":" + headers[i][1]+ ";"   ;
        }
        this.$rest.push({df:new Date(),headers: h, url: url});
    }

    public addWs(dir: "IN" | "OUT", url: string) {
        this.$ws.push({df:new Date(),dir: dir, url: url});
    }

    public ClearRest()
    {
        this.$rest=[];
    }

    public ClearWs()
    {
        this.$ws=[];
    }

    public DisplayRest()
    {
        return this.$rest;
    }

    public DisplayWs()
    {
        return this.$ws;
    }


    public set RestEnable (value:boolean)
    {
        this.restEnable=value;
        this.$rest=[];

    };

    
    public get RestEnable() :boolean
    {
       return this.restEnable;

    };
    
    public set WsEnable (value:boolean)
    {
        this.wsEnable=value;
        this.$ws=[];

    };

    
    public get WsEnable() :boolean
    {
       return this.wsEnable;

    };

}

const lo = new Lo();
export default lo;

