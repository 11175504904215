import {CircularProgress, IconButton, Sheet, Typography} from "@mui/joy";
import RefreshIcon from '@mui/icons-material/Refresh';


function CaptionPanel(props: {
    caption: string,
    status: () => boolean,
    refresh: () => void,
}) {

    return (
        <>

            <Sheet
                sx={{
                    height: 'var(--Panel-height))',
                    borderRadius: 'md',
                    borderStyle: "solid",
                    borderColor: "neutral.softHoverBg",
                    borderWidth: 1,
                    //backgroundColor: 'neutral.softBg',
                    backgroundColor: 'focusVisible',
                    p: 0.5,
                    boxShadow: 'sm',
                }}
            >
                
                <Typography 
                level="title-sm"
                    //textColor={"text.tertiary"}
                    textColor={"common.white"}
                    startDecorator={
                        props.status() ? (
                            <IconButton color="neutral">
                                <CircularProgress color="primary" />
                            </IconButton>) :
                            (<IconButton color="primary" 
                                sx={{color: "common.white",
                                    ":hover": {
                                        backgroundColor: "primary.400"
                                      }
                                }}
                             onClick={() => props.refresh()}>
                                <RefreshIcon />
                            </IconButton>)
                    }
                >{props.caption}</Typography>
            </Sheet>
        </>
    );
}

export {CaptionPanel};
