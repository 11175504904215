import {RestCallback, genericClient} from "./GenericClient";

class BotUsersRest {

    api:string=genericClient.setUrl( "/api/users");

    public getUsers(callback:RestCallback,queryparams?:[string, string][])
    {
        genericClient.callHttp(this.api,"GET",[['Content-Type', 'application/json']],callback,undefined,undefined,queryparams);
    }

    public getUsersPrepared(queryparams?:[string, string][])
    {
       return genericClient.prepareCallHttp(this.api,"GET",[['Content-Type', 'application/json']],undefined,undefined,queryparams);
    }

    public postUsers(callback:RestCallback,body:string)
    {
        genericClient.callHttp(this.api,"POST",[['Content-Type', 'application/json']],callback,undefined,body);

    }

    public DeleteUsers(callback:RestCallback, userID:string, body:string)
    {
        genericClient.callHttpDelete(this.api+"/"+userID,"DELETE",[],callback,undefined);

    }

    public ChangeUsers(callback:RestCallback, body:string)
    {
        genericClient.callHttp(this.api,"PATCH",[['Content-Type', 'application/json']],callback,undefined,body);

    }

    

}
const botUsersRest = new BotUsersRest();

export {botUsersRest};