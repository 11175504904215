import * as React from 'react';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton, {MenuButtonProps} from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';

import {setLocale, getLocale} from '../BaseStylesUtils';
import {useTranslation} from "react-i18next";

export default function ToggleLocale(props: MenuButtonProps) {


    const [open, setOpen] = React.useState(false);
    const {i18n} = useTranslation();
    const [activeLocale, setActiveLocale] = React.useState(i18n.resolvedLanguage);

    const handleOpenChange = React.useCallback(
        (event: React.SyntheticEvent | null, isOpen: boolean) => {
            setOpen(isOpen);
        },
        [],
    );

    React.useEffect(() => {
        const locale: string | undefined = getLocale();
        if (locale) {
            setActiveLocale(locale);
            i18n.changeLanguage(locale);
        }
    },[]);


    function activateLocale(locale: string) {
        i18n.changeLanguage(locale);
        setLocale(locale);
        setActiveLocale(locale);

    }
    return (
        <Dropdown open={open} onOpenChange={handleOpenChange} >
            <MenuButton
                sx={props.sx}
                size="sm"
                variant="outlined"
                color="neutral"

            >{activeLocale}</MenuButton>
            <Menu size="sm"
                variant="outlined"
                color="neutral"
            >
                <MenuItem onClick={() => {activateLocale("ru")}}>ru</MenuItem>
                <MenuItem onClick={() => {activateLocale("en")}}>en</MenuItem>

            </Menu>
        </Dropdown>


    );
}
