import {makeAutoObservable} from "mobx";


class CurrentInfoStore {
    private flag: "INFO"|"ERROR"|"SUCESS"="ERROR";
    private text:string="";
    private color:"danger"|"success"|"primary"="primary";
    private show:boolean=false;



    public constructor() {
        makeAutoObservable(this);
    }

    public get Text() {
        return this.text;
    }

    
    public get Flag() {
        return this.flag;
    }
    public get isShow() {
        return this.show;
    }
    public get Color() {
        return this.color;
    }

    public hide() {
        this.show=false;
    }

    public setInfo(text:string,flag?:"INFO"|"ERROR"|"SUCESS",show?:boolean) {
        this.text = text;
        this.flag = (flag)?flag:"ERROR";
        this.show = (show!==undefined)?show:true;
        switch(this.flag) { 
            case "ERROR": { 
               this.color="danger" ;
               break; 
            } 
            case "SUCESS": { 
                this.color="success";
               break; 
            } 
            default: { 
                this.color="primary"; 
               break; 
            } 
         } 

    }

}

const currentInfoStore = new CurrentInfoStore();
export default currentInfoStore;



