import * as React from 'react';
import Badge from '@mui/joy/Badge';
import Avatar, {AvatarProps} from '@mui/joy/Avatar';
import {Typography} from '@mui/joy';

type AvatarWithStatusProps = AvatarProps & {
  online?: boolean;
  role: "USER" | "AGENT" | "BOT" | undefined;
};

export default function AvatarWithStatus(props: AvatarWithStatusProps) {
  const {online = false, role, ...other} = props;
  return (
    <div>
      <Badge
        color={online ? 'primary' : 'neutral'}
        variant={'solid'}
        size="sm"
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        badgeInset="6px 6px"
      >
        <Avatar size="sm" {...other} />
      </Badge>

    </div>
  );
}
