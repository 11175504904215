enum Priority {
    Cricical = "1",
    Hight ="2",
    Medium ="3",
   Low = "4",
  }

  enum TypesOfUsers {
    BotFather = "1",
    Agents ="2",
    Users ="3",
   Bots = "4",
  }

  enum DialogStatus {
    Open = "1",
    Close ="2"
  }

export  {Priority,TypesOfUsers,DialogStatus}

