import {makeAutoObservable} from "mobx";
import MsgType from "../types/MsgType";
import {identity, identity_null, ustring} from "../types/Alias";
import UserType from "../types/UserType";
import usersStore from "./UsersStore";
import dialogsStore from "./DialogsStore";
import currentContext from "./CurrentContext";
import { dialogRest } from "../net/rest/DialogRest";
import currentInfoStore from "./CurrentInfoStore";





/**
 * класс MsgStore отвечает за хранение и предоставление данных по списку сообщений
 */
class MsgStore {

    private $msg: MsgType[] = [];
    private $msguserid: identity = identity_null;
    private $VisibleLoadButton: boolean = true;
    
    public constructor() {
        makeAutoObservable(this);
    }


    public getmsguserid(){
        return this.$msguserid;
    }

    public setmsguserid(userid:identity){
        this.$msguserid = userid;
    }

    public setVisibleLoadButton(value:boolean){
        this.$VisibleLoadButton = value;
    }

    /**Добавляет сообщение в Store */
    public addMsg(_value: MsgType) {

        //SYNC TYPES !!!
        const value: MsgType =
        {
            trackingid: String(_value.trackingid),
            userid: String(_value.userid),
            dialogid: String(_value.dialogid),
            messagetypeid: Number(_value.messagetypeid),
            content: String(_value.content),
            replymessageid: String(_value.replymessageid),
            senttime: String(_value.senttime),
            attachments:_value.attachments,
            isme: _value.isme

        }
        const CurrentUser:UserType|undefined = usersStore.getUserByID(currentContext.UserID);
        const SendUser: UserType|undefined = usersStore.getUserByID(value.userid);
        if(SendUser){
            value.sender = SendUser.nameds;
            if(SendUser===CurrentUser){
                value.isme = false;
            }else{
                value.isme = true;
            }
        }

        let i: number = this.$msg.findIndex((valueF: MsgType) => valueF.trackingid === value.trackingid);
        if (i >= 0) {
            this.$msg[i] = { ...value };
        } else {
            this.$msg.push(value);
        }

    }

    /**Добавляет историю сообщений в Store REST  */
    public addMsgHistory(_values: MsgType[], DialogID: string) {
        this.clearMsg();
        if (_values.length<10){
            this.$VisibleLoadButton=false;
        }else{
            this.$VisibleLoadButton=true;
        }
        // for (let i = 0; i < _values.length; i++) {
        for (let i = _values.length-1; i >= 0; i--) {
            let _value = _values[i];

            _value.dialogid = DialogID;
            _value.trackingid = "History"

            //SYNC TYPES !!!
            const value: MsgType =
            {
                trackingid: String(_value.trackingid),
                userid: String(_value.senderid),
                dialogid: String(_value.dialogid),
                messageid: String(_value.messageid),
                messagetypeid: Number(_value.messagetypeid),
                content: String(_value.content.substring(1, _value.content.length - 1)),
                replymessageid: String(_value.replymessageid),
                senttime: String(_value.senttime),
                attachments:_value.attachments,
                isme: _value.isme

            }
            const CurrentUser: UserType | undefined = usersStore.getUserByID(currentContext.UserID);
            const SendUser: UserType | undefined = usersStore.getUserByID(value.userid);
            if (SendUser) {
                value.sender = SendUser.nameds;
                if (SendUser === CurrentUser) {
                    value.isme = false;
                } else {
                    value.isme = true;
                }
            }

            if (!(value.content === '')) {
                let i: number = this.$msg.findIndex((valueF: MsgType) => valueF.messageid === value.messageid);
                if (i >= 0) {
                    this.$msg[i] = { ...value };
                } else {
                    this.$msg.push(value);
                }
            }
        }

    }

    /**Добавляет историю сообщений в Store REST  */
    public addPreviosMsg(_values: MsgType[], DialogID: string) {

        if (_values.length<10){
            this.$VisibleLoadButton=false;
        }else{
            this.$VisibleLoadButton=true;
        }
         for (let i = 0; i < _values.length; i++) {
        //for (let i = _values.length-1; i >= 0; i--) {
            let _value = _values[i];

            _value.dialogid = DialogID;
            _value.trackingid = "History"

            //SYNC TYPES !!!
            const value: MsgType =
            {
                trackingid: String(_value.trackingid),
                userid: String(_value.senderid),
                dialogid: String(_value.dialogid),
                messageid: String(_value.messageid),
                messagetypeid: Number(_value.messagetypeid),
                content: String(_value.content.substring(1, _value.content.length - 1)),
                replymessageid: String(_value.replymessageid),
                senttime: String(_value.senttime),
                isme: _value.isme

            }
            const CurrentUser: UserType | undefined = usersStore.getUserByID(currentContext.UserID);
            const SendUser: UserType | undefined = usersStore.getUserByID(value.userid);
            if (SendUser) {
                value.sender = SendUser.nameds;
                if (SendUser === CurrentUser) {
                    value.isme = false;
                } else {
                    value.isme = true;
                }
            }

            if (!(value.content === '')) {
                let i: number = this.$msg.findIndex((valueF: MsgType) => valueF.messageid === value.messageid);
                if (i >= 0) {
                    this.$msg[i] = { ...value };
                } else {
                    this.$msg.unshift(value);
                }
            }
        }

    }

    /**Возвращает сообщение по TrackID */
    public GetMSGByTrackID(value: string){
        let i: number = this.$msg.findIndex((valueF: MsgType) => valueF.trackingid === value);
        return  this.$msg[i];
    }

    /** возвращает список сообщений */
    public getMsg(): MsgType[] {
        return this.$msg;
    }


    /** чистит список 
    */
    public clearMsg() {
        this.$msg = [];

    }    

    
    //display
    public Display(): MsgType[] {
        return this.$msg.filter((value:MsgType)=>value.dialogid===dialogsStore.Selected?.dialogid).slice();
    }

    public LoadMsg(MsgSize:string, path:boolean, messageid?:string){


        const DialogID = dialogsStore.Selected?.dialogid;
        let IdD: string = "";
        if (DialogID !== undefined) {
            IdD = DialogID;
        }
        const queryparams: [string, string][] =
            [
                ["pagesize", String(MsgSize)],
                ["page", "0"],
                ["dialogid", String(IdD)],
            ];
        if (path){
            queryparams.push(["messageidprevius", String(messageid)]);
        }
        dialogRest.getDialogsMessages({
            sucess: (data: any) => {
                if (path){
                    msgStore.addPreviosMsg(data.content[0].messages, data.content[0].dialogid);
                }else{
                    msgStore.addMsgHistory(data.content[0].messages, data.content[0].dialogid);
                }
            },
            error: (data: any) => {
                currentInfoStore.setInfo("dialogsMessages " + data, "ERROR");
            }
        }, queryparams);
    }

    public BackLoad() {

        let find_key: number = -1, senttime: number = 0;
        this.$msg.forEach((value, key) => {
            if (senttime === 0) {
                find_key = key;
                senttime = Number(value.senttime);
            }
            else {
                if (Number(value.senttime) < senttime) {
                    find_key = key;
                    senttime = Number(value.senttime);
                }
            }
        });

        if(!(find_key===-1)){
            this.LoadMsg("10", true, this.$msg[find_key].messageid);
        }
    }

    public VisibleButton():boolean{
        if ((dialogsStore.Selected)&&(this.$VisibleLoadButton)){
            return true;
        }else{
            return false;
        }
        
    }

      /** возвращает список сообщений по списку id
    * используется фильтрация массива там где у элемента id в списке ids переданной в сигнатуре
   */
      public getMSGByIDs(ids_list: identity[]): MsgType[] {
        return this.$msg.filter((value: MsgType) => {
            if(value.messageid){
            return ids_list.indexOf(value.messageid) >= 0;
            }
        });
    }

    public getMsgByID(id: identity): MsgType | undefined {
        const msg = this.getMSGByIDs([id]);
        return msg.length === 0 ? undefined : msg[0];
    }
   }


const msgStore = new MsgStore();
export default msgStore;








