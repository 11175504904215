import {RestCallback, genericClient} from "./GenericClient";

class DialogRest {

    api:string=genericClient.setUrl( "/api/dialogs");

    public getDialogs(callback:RestCallback,queryparams?:[string, string][])
    {
        genericClient.callHttp(this.api,"GET",[],callback,undefined,undefined,queryparams);

    }

    public getDialogsMessages(callback:RestCallback,queryparams?:[string, string][])
    {
        genericClient.callHttp(this.api+"/dialogsMessages","GET",[],callback,undefined,undefined,queryparams);

    }

}
const dialogRest = new DialogRest();

export {dialogRest};