import {FormControl, FormLabel, Box, Typography, Divider, Stack, Avatar, List} from "@mui/joy";
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import {MessageAttachment} from "../components/Attach";
import * as colors from './Colors';

type MessageAttachProp =
  {

    attachment: MessageAttachment;
    me: boolean;
  }
function click(id:string){
  console.log(id)
}

export default function MessageAttach(props: MessageAttachProp) {
  const {attachment, me} = props;

  return (
    <div onDoubleClick={()=>click(attachment.docid)}>
    <Box key={attachment.docid}>
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Avatar color="primary" size="lg">
            <InsertDriveFileRoundedIcon/>
          </Avatar>
          <div>
            <Typography fontSize="sm" sx={{
              color: me
              ? colors.fontBubleMe
                : colors.fontBubleThem
            }}>{attachment?.filenname}</Typography>
            <Typography level="body-sm" sx={{
              color: me
              ? colors.fontBubleMe
                : colors.fontBubleThem
            }}>{attachment?.filenname}</Typography>
          </div>
        </Stack>
    </Box>
    </div>
  );
}