import {observer} from "mobx-react-lite";
import docsStore from "../../../store/DocsStore";
import DocsType from "../../../types/DocsType";
import {Avatar, List, ListItem, ListItemButton, ListItemDecorator, ListSubheader, Sheet, Typography} from "@mui/joy";
import { FileCopy, Group, Person } from "@mui/icons-material";

const ContentPanel = observer(() => {
    return (
        <> <List >

        {docsStore.getDocs().map((value:DocsType) =>         
            (
              <ListItem key={value.docid} > 
                <ListItemDecorator> 
                    <FileCopy /> 
                  </ListItemDecorator>
               <ListItemButton variant="outlined">{value.filenameds}</ListItemButton> 
              </ListItem>
            ))}
        </List></>
    );
});

export {ContentPanel};
