import {ButtonGroup, IconButton, Sheet} from "@mui/joy";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import {useTranslation} from "react-i18next";
import { Download, DownloadDone, NoteAlt } from "@mui/icons-material";



function EditButtonsPanel(props: {
    add?:()=>void,
    update?:()=>void,
    delete?:()=>void,
    download?:()=>void
}) {

    const {t} = useTranslation();
    
    return (
        <>
            <Sheet
                sx={{
                    borderRadius: 'md',
                    borderStyle: "solid",
                    borderColor: "neutral.softHoverBg",
                    borderWidth: 1,
                    backgroundColor: 'neutral.softBg',
                    p: 1,
                    maxHeight: 'var(--Panel-height))',
                }}
            >
                <ButtonGroup size="md" spacing={"3"}>
                    {(props.add) 
                    && (
                    <IconButton color="primary" variant="soft" onClick={() => {if (props.add)props.add()}}><NoteAddIcon /></IconButton>
                    )}
                    {(props.update) 
                    && (
                    <IconButton color="primary" variant="soft" onClick={() => {if (props.update)props.update()}}><EditIcon /></IconButton>
                    )}
                    {(props.delete) 
                    && (
                    <IconButton color="primary" variant="soft" onClick={() => {if (props.delete)props.delete()}}><DeleteIcon /></IconButton>
                    )}    
                              {(props.download) 
                    && (
                    <IconButton color="primary" variant="soft" onClick={() => {if (props.download)props.download()}}><Download/></IconButton>
                    )}                    

                </ButtonGroup>
            </Sheet>
        </>
    );
}



export {EditButtonsPanel};