import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import BaseLayout from './tmp/BaseLayout';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BaseLayout />
);



