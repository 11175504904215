import { Box, Sheet, Typography } from "@mui/joy";
import {observer} from "mobx-react";
import { LeftPanel } from "./LeftPanel";
import { ContentPanel } from "./ContentPanel";

const Layout=observer( () => {

    return (
      <>
            <Sheet
                sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        xs: 'minmax(min-content, min(5%, 50px)) 1fr',
                        sm: 'minmax(min-content, min(10%, 100px)) 1fr',
                        md: 'minmax(min-content, min(10%, 100px)) 1fr',
                    }
                }}>
                <Sheet
                    sx={{
                        position: { xs: 'fixed', sm: 'sticky' },
                        zIndex: 100,
                        width: '100%',
                        top: 'var(--Header-height)',
                    }}
                >
                    <LeftPanel />
                </Sheet>
                <Sheet sx={{
                        position: { xs: 'fixed', sm: 'sticky' },
                        zIndex: 100,
                        width: '100%',
                        top: 'var(--Header-height)',
                    }}>
                    <ContentPanel />
                </Sheet>
            </Sheet>
        </>

    );
    });

export { Layout};
