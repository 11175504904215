import {Box, IconButton, Sheet, Tab, Table, TabList, TabPanel, Tabs, Typography} from "@mui/joy";
import {observer} from "mobx-react";
import lo from "../../../store/Logging";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';


const Layout = observer(() => {

    return (
        <>
            <Sheet
                sx={{

                    width: "100%",
                    height: 'calc(100dvh - var(--Header-height))',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: "auto"
                }}>
                <Tabs aria-label="Disabled tabs" defaultValue={0}>
                    <TabList>
                        <Tab disabled={!lo.RestEnable}>Rest urls</Tab>
                        <Tab disabled={!lo.WsEnable}>Ws messages</Tab>
                    </TabList>
                    <TabPanel value={0}>
                        {(lo.RestEnable) &&
                            (
                                <>
                                <IconButton onClick={()=>lo.ClearRest()}><DeleteSweepIcon/></IconButton>
                                    <Table aria-label="basic table">
                                        <tbody>
                                            {
                                                lo.DisplayRest().map((v) => {
                                                    return (
                                                        <> <tr>
                                                            <td width={200}>{v.df+" "}</td>
                                                            <td width={200}>{v.headers}</td>
                                                            <td>{v.url}</td>

                                                        </tr></>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </>
                            )
                        }

                    </TabPanel>
                    <TabPanel value={1}>
                    {(lo.WsEnable) &&
                            (
                                <>
                                <IconButton onClick={()=>lo.ClearWs()}><DeleteSweepIcon/></IconButton>
                                    <Table aria-label="basic table">
                                        <tbody>
                                            {
                                                lo.DisplayWs().map((v) => {
                                                    return (
                                                        <> <tr>
                                                            <td width={200}>{v.df+" "}</td>
                                                            <td width={50}>{v.dir}</td>
                                                            <td>{v.url}</td>

                                                        </tr></>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </>
                            )
                        }
                    </TabPanel>

                </Tabs>
            </Sheet>
        </>

    );
});

export {Layout};
