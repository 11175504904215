import {Group, Info, KeyboardArrowRight, Person, SpaceBar} from "@mui/icons-material";
import {Box, Button, ButtonGroup, CircularProgress, Divider, IconButton, List, ListItem, ListItemButton, ListItemContent, ListItemDecorator, ListSubheader, Sheet, Typography} from "@mui/joy";
import {observer} from "mobx-react-lite";
import teamStore from "../../../store/TeamStore";
import TeamType from "../../../types/TeamType";
import {StyledListItem} from "@mui/joy/ListItem/ListItem";
import * as React from 'react';
import {teamsRest} from "../../../net/rest/TeamsRest";
import currentInfoStore from "../../../store/CurrentInfoStore";
import {useTranslation} from "react-i18next";

import RefreshIcon from '@mui/icons-material/Refresh';
import {EditDialog, EditDialogDescriptor, initEditDialogDescriptor} from "../../../components/EditDialog";
import {identity, ustring} from "../../../types/Alias";
import {EditButtonsPanel} from "../../../components/EditButtonsPanel";
import {CaptionPanel} from "../../../components/CaptionPanel";
import {ListDecorator} from "../../../components/ListDecorator";


const LeftPanel = observer(() => {

    const {t} = useTranslation();

    //Dialogs state
    const [dlgDescriptor, setDlgDescriptor] = React.useState<EditDialogDescriptor>(initEditDialogDescriptor(handlResultDialog));

    //Dialogs close
    function closeDialog() {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            return {anchor: undefined, visible: false, elements: [], handlResult: reset.handlResult};
        });
    }


    //Dialogs show
    function showDialog() {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            reset.visible = true;
            return {...reset};
        });
    }


    //Dialogs process result
    function handlResultDialog(anchor: identity | undefined, isapplied: boolean, result?: Map<string, ustring>) {
        closeDialog();
        // TODO
        if (isapplied) {

            switch (anchor) {
                case "ADD":                   

                    if (!result?.get("teamName")){
                        currentInfoStore.setInfo("Введите имя для пользователя", "ERROR");
                        break;
                    }
                    let newUserAudit = teamStore.getTeamByName(result?.get("teamName"));
                    if(newUserAudit!==undefined){
                        currentInfoStore.setInfo("Пользователь с именем " + result?.get("teamName") + " уже существует", "ERROR");
                        break;
                    }
                    setShowStatusLeft(true);

                    const json= {
                        teamds: result?.get("teamName") || "NoName",
                        managerid: undefined,
                        managerds: "",
                        members: {}
                    }

                    teamsRest.postTeams(

                        {
                            sucess: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                                teamStore.addTeam(
                                    {
                                        teamid: data.content[0].teamid,
                                        teamds: envoy.nameds,
                                        managerid: undefined,
                                        managerds: "",
                                        members: [],
                                    }

                                )

                            },
                            error: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                                currentInfoStore.setInfo("Teams " + data, "ERROR");
                            },
                            envoy: {id: (new Date()).getTime() + "", nameds: result?.get("teamName") || "NoName"}

                        },
                        JSON.stringify(json)
                    );
                    break;
                case "UPD":
                    if (!result?.get("teamName")){
                        currentInfoStore.setInfo("Для наименования команды нет изменений", "ERROR");
                        break;
                    }
                    let changeTeamAudit = teamStore.getTeamByName(result?.get("teamName"));
                    if(changeTeamAudit!==undefined){
                        currentInfoStore.setInfo("Пользователь с именем " + result?.get("teamName") + " уже существует", "ERROR");
                        break;
                    }
                    const selecledTeam = teamStore.getTeamByID(teamStore.SelectedIdteam);
                    if (selecledTeam) {
                        
                        setShowStatusLeft(true);
                        const json= {
                            teamid:    selecledTeam.teamid,
                            teamds:    result?.get("teamName") || "NoName",
                        }
                        
    
                        teamsRest.ChangeTeams(
    
                            {
                                sucess: (data: any, envoy?: any) => {
                                    setShowStatusLeft(false);
                                    if (data.message!==undefined){
                                        currentInfoStore.setInfo("Teams " + data.message, "ERROR");
                                    }
                                    else{
                                        selecledTeam.teamds = result?.get("teamName") || "NoName";
                                    }
                                       
                                },
                                error: (data: any, envoy?: any) => {
                                    setShowStatusLeft(false);
                                    currentInfoStore.setInfo("Teams " + data, "ERROR");
                                },
                                
                            },
                            JSON.stringify(json)
                        );
                    }

                    break;

                    break;
                case "DEL":
                    const jsonDel = teamStore.getTeamByID(teamStore.SelectedIdteam);
                    teamsRest.DeleteTeams(

                        {
                            sucess: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                    
                                    const selecledTeamsDelete = teamStore.getTeamByID(teamStore.SelectedIdteam);
                                    if (selecledTeamsDelete) {
                                        teamStore.deleteTeam(selecledTeamsDelete);
                                    

                                    }},
                            error: (data: any, envoy?: any) => {
                                setShowStatusLeft(false);
                                currentInfoStore.setInfo("Teams " + data, "ERROR");
                            },
                        },
                        teamStore.SelectedIdteam,JSON.stringify(jsonDel)
                    );
                    


                    break;
                default:
                    break;
            }


        }


    }

    //Adding
    function addRecord() {
        dlgDescriptor.title = "Add record";
        dlgDescriptor.text = "Adding new team";
        dlgDescriptor.apply = t("Button_ADD");
        dlgDescriptor.cancel = t("Button_Cancel");
        dlgDescriptor.elements = [
            {format: "INPUT", key: "teamName", title: t("Elements_Name_Title")},
        ];
        dlgDescriptor.anchor = "ADD";
        showDialog();
    }

    //Updating
    function updateRecord() {
        const selecledTeam = teamStore.getTeamByID(teamStore.SelectedIdteam);
        if (selecledTeam) {
            dlgDescriptor.title = t("Edit_Title");
            dlgDescriptor.text = t("Edit_Text");
            dlgDescriptor.apply = t("Button_change");
            dlgDescriptor.cancel = t("Button_Cancel");
            dlgDescriptor.elements = [{format: "INPUT", key: "teamName", title: t("Elements_Name_Title"), currentValue: selecledTeam.teamds}];
            dlgDescriptor.anchor = "UPD";
            showDialog();
        }
    }

    //Deleting
    function deleteRecord() {
        const selecledTeam = teamStore.getTeamByID(teamStore.SelectedIdteam);
        if (selecledTeam) {
            dlgDescriptor.title = t("Delete_Title");
            dlgDescriptor.cancel = t("Button_Cancel");
            dlgDescriptor.apply = t("Button_Delete");
            dlgDescriptor.text = `${t("Delete_Text")} ${selecledTeam.teamds} ?`;
            dlgDescriptor.elements = [];
            dlgDescriptor.anchor = "DEL";
            showDialog();
        }
    }

    const [refresh, setRefresh] = React.useState<boolean>(false);
    const [showStatusLeft, setShowStatusLeft] = React.useState<boolean>(true);

    function success_processTeams(data: any): void {
        setShowStatusLeft(false);
        data.content.forEach((value: TeamType) => {
            teamStore.addTeam(value);
        })
    }
    function error_processTeams(data: any): void {
        setShowStatusLeft(false);
        currentInfoStore.setInfo("Teams " + data, "ERROR");

    }

    function updateTeams() {
        setShowStatusLeft(true);
        teamStore.clearTeams();
        teamsRest.getTeams({sucess: success_processTeams, error: error_processTeams});
    }


    React.useEffect(() => {
        updateTeams();
        return () => {

        };
    }, [refresh]);


    return (
        <>
            <Sheet
                sx={{
                    width: 300,
                    height: 'calc(100dvh - var(--Header-height))',
                    display: 'flex',
                    flexDirection: 'column',

                }}
            >

                <CaptionPanel caption={t("title_teams")}
                    status={() => showStatusLeft}
                    refresh={() => setRefresh(!refresh)} />



                <ListDecorator>
                    <List size="sm" >
                        {
                            teamStore.getTeams().map((value: TeamType) =>
                            (<ListItem
                                variant={((teamStore.SelectedIdteam === value.teamid)) ? "outlined" : "plain"}
                                key={value.teamid}
                                sx={{
                                    backgroundColor: (teamStore.SelectedIdteam === value.teamid) ? "primary.softHoverBg" : "inherit",

                                }} >

                                <ListItemButton variant="plain"
                                    onClick=
                                    {() => {
                                        teamStore.SelectedIdteam = value.teamid;
                                    }} >
                                    <ListItemDecorator><Group /></ListItemDecorator>
                                    {/* <ListItemContent>{value.nameds} ({value.members.length})</ListItemContent> */}
                                    <ListItemContent>{value.teamds}</ListItemContent>
                                    {(teamStore.SelectedIdteam === value.teamid) && (<KeyboardArrowRight />)}
                                </ListItemButton>
                            </ListItem>
                            ))}
                    </List>
                </ListDecorator>
                <EditButtonsPanel add={addRecord} update={updateRecord} delete={deleteRecord} />
            </Sheet >
            <EditDialog descriptor={dlgDescriptor} />
        </>

    );
});

export {LeftPanel};
