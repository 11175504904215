import {makeAutoObservable} from "mobx";
import TeamType from "../types/TeamType";
import {identity, identity_null} from "../types/Alias";


/**
 * класс TeamStore отвечает за хранение и предоставление данных по списку команд
 * и операций над этим список 
 */
class TeamStore {
    private $teams: TeamType[] = [];
    private $selected_idteam: identity = identity_null;

    public constructor() {
        makeAutoObservable(this);
    }

    /** возвращает список команд */
    public getTeams(): TeamType[] {
        return this.$teams;
    }

    /** возвращает список команд по списку id
    * используется фильтрация массива там где у элемента id в списке ids переданной в сигнатуре
   */
    public getTeamByIDs(ids_list: identity[]): TeamType[] {
        return this.$teams.filter((value: TeamType) => {
            return ids_list.indexOf(value.teamid) >= 0;
        });
    }

    public getTeamByID(id: identity): TeamType | undefined {
        const teams = this.getTeamByIDs([id]);
        return teams.length === 0 ? undefined : teams[0];
    }

    /** возвращает список команд по списку names
    * используется фильтрация массива 
   */
    public getTeamByNames(names_list: string[]): TeamType[] {
        return this.$teams.filter((value: TeamType) => {
            return names_list.indexOf(value.teamds) >= 0;
        });

    }

    public getTeamByName(name: string | any): TeamType | undefined {
        const user = this.getTeamByNames([name]);
        return user.length === 0 ? undefined : user[0];
    }

    /** чистит список 
   */
    public clearTeams() {
        this.$selected_idteam = identity_null;
        this.$teams = [];

    }

   
    /** add Team 
    */
    public addTeam(_value: TeamType) {

        //SYNC TYPES !!!
        const value: TeamType =
        {
            teamid: String(_value.teamid),
            teamds: String(_value.teamds),
            managerid: (_value.managerid) ? String(_value.managerid) : undefined,
            managerds: (_value.managerds) ? String(_value.managerds) : undefined,
            members: [
                ..._value.members.map((elem) => {
                    return {
                        userid: String(elem.userid),
                        userds: String(elem.userds)
                    }
                })
            ]

        }

        let i: number = this.$teams.findIndex((valueF: TeamType) => valueF.teamid === value.teamid);
        if (i >= 0) {
            this.$teams[i] = {...value};
        } else {
            this.$teams.push(value);
        }

    }

    /** удаляет команды 
    */
    public deleteTeams(teams: TeamType[]) {
        teams.forEach((value: TeamType) => {
            let i: number = this.$teams.findIndex((valueF: TeamType) => valueF.teamid === value.teamid);
            if (i >= 0) {
                this.$teams.splice(i, 1);
            }
        });
    }
    /** удаляет команду
        */
    public deleteTeam(teams: TeamType) {

        let i: number = this.$teams.findIndex((valueF: TeamType) => valueF.teamid === teams.teamid);
        if (i >= 0) {
            this.$teams.splice(i, 1);
        }
    }

    //get by selected teamid
    public get SelectedIdteam(): identity {
        return this.$selected_idteam;
    }
    //set selected by teamid
    public set SelectedIdteam(value: identity) {
        this.$selected_idteam = value;
    }
}

const teamStore = new TeamStore();
export default teamStore;