import {observer} from "mobx-react-lite";
import teamStore from "../../../store/TeamStore";
import {AspectRatio, Avatar, Box, Button, Card, CardContent, CardOverflow, IconButton, List, ListItem, ListItemButton, ListItemDecorator, ListSubheader, Sheet, Stack, Typography} from "@mui/joy";
import { Android, Delete, Group, Height, KeyboardArrowRight, Person } from "@mui/icons-material";
import usersStore from "../../../store/UsersStore";
import UserType from "../../../types/UserType";
import React from "react";
import { EditButtonsPanel } from "../../../components/EditButtonsPanel";
import { EditDialog, EditDialogDescriptor, initEditDialogDescriptor } from "../../../components/EditDialog";
import { identity, ustring } from "../../../types/Alias";
import BotUsersStore from "../../../store/UsersStore";
import currentInfoStore from "../../../store/CurrentInfoStore";
import { teamsRest } from "../../../net/rest/TeamsRest";
import { botUsersRest } from "../../../net/rest/BotUsersRest";
import { useTranslation } from "react-i18next";
import TeamsEditButtonsPanel from "./components/TeamsEditButtonsPanel";
import Grid from '@mui/joy/Grid';



const ContentPanel = observer(() => {

  const {t} = useTranslation();
  const [refresh, setRefresh] = React.useState<boolean|undefined>(false);
  //Dialogs state
  const [dlgDescriptor, setDlgDescriptor] = React.useState<EditDialogDescriptor>(initEditDialogDescriptor(handlResultDialog));
  
    //Dialogs close
    function closeDialog() {
      setDlgDescriptor((reset: EditDialogDescriptor) => {
          return {anchor: undefined, visible: false, elements: [], handlResult: reset.handlResult};
      });
  }


  //Dialogs show
  function showDialog() {
      setDlgDescriptor((reset: EditDialogDescriptor) => {
          reset.visible = true;
          return {...reset};
      });
  }

  //Dialogs process result
  function handlResultDialog(anchor: identity | undefined, isapplied: boolean, result?: Map<string, ustring>) {
    closeDialog();
    // TODO
    if (isapplied) {

      switch (anchor) {
        case "ADD":
          if (!teamStore.SelectedIdteam) {
            currentInfoStore.setInfo(t("TeamERR_NoTeam"), "ERROR");
            break;
          }


          const json = {
            teamid: teamStore.SelectedIdteam,
            members: [
              {
                userid: result?.get("UserID")
              }
            ]
          }

          teamsRest.AddMembersTeams(

            {
              sucess: (data: any, envoy?: any) => {
                updateUsers();                
              },
              error: (data: any, envoy?: any) => {
                currentInfoStore.setInfo("Teams " + data, "ERROR");
              },
              envoy: { id: (new Date()).getTime() + "", teamds: result?.get("teamName") || "NoName" }

            },
            JSON.stringify(json)
          );
          break;

        case "DEL":
          if (!teamStore.SelectedIdteam) {
            currentInfoStore.setInfo(t("TeamERR_NoTeam"), "ERROR");
            break;
          }
          const jsonDel = {
            teamid: teamStore.SelectedIdteam,
            members: [
              {
                userid: usersStore.SelectedIdUser
              }
            ]
          }

          teamsRest.DeleteMembersTeams(

            {
              sucess: (data: any, envoy?: any) => {
                updateUsers();
              },
              error: (data: any, envoy?: any) => {
                currentInfoStore.setInfo("Teams " + data, "ERROR");
              },
              envoy: { id: (new Date()).getTime() + "", ds_team: result?.get("teamName") || "NoName" }

            },
            JSON.stringify(jsonDel)
          );

          break;
        default:
          break;
      }


    }


}

   //Adding
  function addRecord() {
    dlgDescriptor.title = t("TeamMember_Add_Title");
    dlgDescriptor.text = t("TeamMember_Add_text");
    dlgDescriptor.apply = t("Button_ADD");
    dlgDescriptor.cancel = t("Button_Cancel");
    dlgDescriptor.elements = [
      {
        format: "SELECT", key: "UserID", title: t("TeamMember_attribute_name"), currentValue: "c4", source: [ 
          ...BotUsersStore.Display().map<{code:string,value:string}>((item:UserType) =>  {return {code: item.userid,value:item.nameds}})]}]
    dlgDescriptor.anchor = "ADD";
    showDialog();
  }

//Deleting
function deleteRecord() {
    const selecledUser = usersStore.getUserByID(usersStore.SelectedIdUser);
    if (selecledUser) {
        dlgDescriptor.title = t("TeamMember_Delete_Title");
        dlgDescriptor.cancel = t("Button_Cancel");
        dlgDescriptor.apply = t("Button_Delete");
        dlgDescriptor.text = `${t("Delete_Text")} ${selecledUser.nameds} ?`;
        dlgDescriptor.elements = [];
        dlgDescriptor.anchor = "DEL";
        showDialog();
    }
    else{ 
      currentInfoStore.setInfo(t("TeamERR_NoTeam"), "ERROR");
    }
}

function updateUsers() {
  BotUsersStore.clearUsers();
  botUsersRest.getUsers({sucess: success_processUsers, error: error_processUsers},[["size","100"]]);
}

function success_processUsers(data: any): void {
  data.content.forEach((value: UserType) => {
      usersStore.addUser(value);             
  })
}
function error_processUsers(data: any): void {
  currentInfoStore.setInfo("Bots " + data, "ERROR");

}

  React.useEffect(() => {
    if (refresh!==undefined)
    {
      updateUsers();
      setRefresh(undefined)
   }
    return () => {

    };
}, [refresh,teamStore.SelectedIdteam]);
  
    return (
      <>
        <Box
          sx={{height: 'calc(100dvh - var(--Header-height))',
          flexDirection: 'column',
          display: 'flex',
          zIndex:'1000',
        }}>
          <TeamsEditButtonsPanel add={addRecord} delete={deleteRecord} lableText={t("title_user_Teams")}/>
          

          <Box display={"flow"}
            sx={{
              overflow:'auto',
              width: '100%',
              height: 'calc(90dvh - var(--Header-height))',
              display: 'grid',
              gridTemplateColumns:'repeat(auto-fill,minmax(250px,5fr))',
              gridRow: 'auto',
              gap: 1,
              gridAutoRows: '100px',
              zIndex:'1001'
            }}>
            {
              usersStore.getUsersByТeamsIDs([teamStore.SelectedIdteam]).map((value: UserType) =>
              (
                <Card orientation="horizontal"
                  variant={((usersStore.SelectedIdUser === value.userid)) ? "outlined" : "plain"}
                  sx={{
                    height: '100%',
                    backgroundColor: (usersStore.SelectedIdUser === value.userid) ? "#0B6BCB" : (value.usertypeid === "4") ? "primary.softHoverBg" : "#E3FBE3",
                  }}
                  color="neutral"
                  onClick=
                  {() => {
                    usersStore.SelectedIdUser = value.userid;
                  }}>
                  <CardOverflow>
                    <AspectRatio ratio="1" sx={{ width: '50px', }}>
                      {((value.usertypeid === "4")) ? <Android /> : <Person />}
                    </AspectRatio>
                  </CardOverflow>
                  <CardContent sx={{height:'500px'}}>
                    <Typography textColor="common.black" sx={{ fontWeight: 'md' }}>
                      {value.nameds}
                    </Typography>
                  </CardContent>
                </Card>
              ))
            }

          </Box>
          
          {/* <List sx={{overflow: 'auto',height: 'calc(80dvh - var(--Header-height))', display: 'flex',}}>
            {
              usersStore.getUsersByТeamsIDs([teamStore.SelectedIdteam]).map((value: UserType) =>
              (
                <ListItem 
                  variant={((usersStore.SelectedIdUser === value.userid)) ? "outlined" : "plain"}
                  key={value.userid}
                  sx={{ 
                    backgroundColor: (usersStore.SelectedIdUser === value.userid) ? "primary.softHoverBg" : "inherit",
                  }} >
                  <ListItemButton variant="plain"
                    onClick=
                    {() => {
                      usersStore.SelectedIdUser = value.userid;
                    }}>
                      <ListItemDecorator>
                      <Person />
                    </ListItemDecorator>
                    <ListSubheader>{value.userid}</ListSubheader>
                    {value.nameds}{(usersStore.SelectedIdUser === value.userid) && (<KeyboardArrowRight />)}</ListItemButton>
                </ListItem>
              ))
            }

          </List> */}
        
          <EditDialog descriptor={dlgDescriptor} />
        </Box>
      </>
    );
});

export {ContentPanel};
