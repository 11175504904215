import {RestCallback, genericClient} from "./GenericClient";

class RedirectRest {

    api:string=genericClient.setUrl( "/api/dialogs");

    public redirectToUser(callback:RestCallback,queryparams?:[string, string][])
    {
        genericClient.callHttp(this.api+"/redirecttouser","POST",[],callback,undefined,"",queryparams);
    }

    public redirectToTeam(callback:RestCallback,queryparams?:[string, string][])
    {
        genericClient.callHttp(this.api+"/redirecttoteam","POST",[],callback,undefined,"",queryparams);
    }



}
const redirectRest = new RedirectRest();

export {redirectRest};